import DataTableComponent from '../components/DataTableComponent'
import { columnsEvents } from '../utils/events-data'
import React, { useState, useEffect } from 'react'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'
import EventsCalendar from '../parts/EventsCalendar'
import ChooseDataView from '../parts/ChooseDataView'
import EventsPerWeek from '../components/EventsPerWeek'


const EventPage = () => {
	const [dataEvent, setDataEvent] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(50)
	const [view,setView] = useState(3)
	const navigate = useNavigate()
	
	const {
		state: { user },
	} = useApp()

	const getEventsData = async (page, limit = perPage, sortDirection = 'asc', sortField = 'id', searchTerm = null) => {
		setLoading(true)
		const offset = (page - 1) * limit
		const result = await getDataApi(
			user.lastVenue,
			'event',
			offset,
			limit,
			null,
			null,
			sortField,
			sortDirection,
			searchTerm
		)
		//const eventsDataResponse = result.data
		const eventsDataResponse = Object.values(result.data).map((event) => {
			return {
				id: event.id,
				name: event.name,
				date: event.date,
				shiftId: event.shiftId,
				createdAt: event.createdAt,
				updatedAt: event.updatedAt,
			}
		})
		setDataEvent(eventsDataResponse)
		setTotalRows(result.count)
		setLoading(false)

	}

	useEffect(() => {
		if (view === 1) {
			getEventsData(1, perPage)
		}
	}, [perPage])
	useEffect(() => {
		if (view === 1) {
			localStorage.setItem('events-view', view.toString());
		}
	}, [view]);
	
	const handleNew = (e) => {
		const toUrl = `/event/edit/0`
		navigate(toUrl)
	}

	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<div className="d-sm-flex justify-content-between align-items-center my-3">
							{/* < ChooseDataView 
								activeView={view}
								views={[
									{name: 'Tabla', icon: 'ti-table', id: 1},
									{name: 'Calendario', icon: 'ti-calendar', id: 2},
									{name: 'Semanal', icon: 'ti-clock', id: 3},
								]}
								onViewChange={(id) => setView(id)}
							/> */}
							<p className="page_title">EVENTOS</p>
							<button type="button" className="btn rmb-btn-primary maxh-37" onClick={handleNew}>
								Nuevo evento
							</button>
						</div>
						{/* { 	view === 1 ? 
							<DataTableComponent
								title="Listado de eventos"
								description="Listado de eventos del tablao"
								columns={columnsEvents}
								data={dataEvent}
								setPerPage={setPerPage}
								loading={loading}
								getData={getEventsData}
								totalRows={totalRows}
								perPage={perPage}
								model="event"
							/> 
							: view === 2 ?
							<EventsCalendar
								model='event'
							/>
							: <EventsPerWeek />
						} */}
						<EventsPerWeek />
					</div>
				</div>
			</div>
		</div>
	)
}
export default EventPage
