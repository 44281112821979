import useApp from '../utils/useApp'

const WelcomeComponent = () => {
	const {
		state: { user },
	} = useApp()
	const today = new Date().toLocaleDateString('es-ES', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
	return (
		<div className="d-flex align-items-center gap-4 mb-4">
			<div className="position-relative">
				<div className="border border-2 border-primary rounded-circle">
					<img src={user.image ? user.image : "https://rumba.cardamomo.com/img/neutral.png"} className="rounded-circle m-1" alt="user1" width="60" height="60" />
				</div>
				<span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
					{' '}
					3 <span className="visually-hidden">notificaciones pendientes</span>
				</span>
			</div>
			<div>
				<h3 className="fw-semibold">
					Hola, <span className="text-dark">{user.name}</span>
				</h3>
				<span>Sin alertas - {today}</span>
			</div>
		</div>
	)
}
export default WelcomeComponent
