import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsLogs = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Type',
        selector: row => row.type,
        sortable: true
    },
    {
        name: 'Ip',
        selector: row => row.ip,
        sortable: true
    },
    {
        name: 'User Id',
        selector: row => row.userId,
        sortable: true
    },
    {
        name: 'Device Id',
        selector: row => row.deviceId,
        sortable: true
    },
    {
        name: 'Action',
        selector: row => row.action,
        sortable: true
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow()
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()}
    },
    {
        name: 'Booking Id',
        selector: row => row.bookingId,
        sortable: true
    }    
]

export { columnsLogs }


