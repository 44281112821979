import DataTableComponent from '../components/DataTableComponent'
import { columnsCashiers } from '../utils/cashiers-data'
import React, { useEffect, useState } from 'react'
import { getCashiers, getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import TotalsCashiersComponent from '../parts/TotalsCashiersComponent'
import CashiersCashBoxesDatatable from '../parts/CashiersCashBoxesDatatable'

const date = new Date()

const CashiersPage = () => {
	const [cashiersData, setCashiersData] = useState([])
	const [cashiersCashBoxes, setCashiersCashBoxes] = useState([])
	const [users, setUsers] = useState([])
	const [filters, setFilters] = useState({
		from: date.toISOString().substring(0, 10),
		to: date.toISOString().substring(0, 10),
		venueId: 1,
		userId: 1,
	})
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(1000)

	const {
		state: { user },
	} = useApp()

	useEffect(() => {
		getUsersData()
		// eslint-disable-next-line
	}, [])

	const getCashiersData = async (flt) => {
		setLoading(true)
		const result = await getCashiers(user.lastVenue, flt)
		console.log(result)
		setCashiersData(result.payments)
		setCashiersCashBoxes(result.cashboxes)
		setTotalRows(result.count)
		setLoading(false)
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target
		const nf = {
			...filters,
			[name]: value,
		}
		if (user.role === 'cashier'){
			const date = new Date(value)
			if (name === 'from') {
				date.setDate(date.getDate() + 1)
				nf.to = date.toISOString().substring(0, 10)
			}
			if (name === 'to') {
				date.setDate(date.getDate() - 1)
				nf.from = date.toISOString().substring(0, 10)
			}
		}

		setFilters(nf)
		getCashiersData(nf)
	}

	const getUsersData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
		setLoading(true)
		const result = await getDataApi(user.lastVenue, 'user', 0, 1000)
		const userDataResponse = Object.values(result.data).map((user) => {
			return {
				id: user.id,
				name: user.name,
				role: user.role,
			}
		})
		userDataResponse.sort((a, b) => (a.name > b.name ? 1 : -1))
		setUsers(userDataResponse)
		setLoading(false)
	}

	return (
		<div className="card" id="cashiers-page-card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<div className="row">
							<div className="col-3">
								<div className="form-group mt-4">
									<h6 className="mb-0 fs-4 mb-2">Negocio</h6>
									<select
										onChange={handleInputChange}
										className="form-control"
										name="venueId"
										value={filters.venueId}
										defaultValue={filters.venueId}
									>
										<option value="1">Cardamomo</option>
										<option value="2">1911</option>
									</select>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group mt-4">
									<h6 className="mb-0 fs-4 mb-2">Usuario</h6>
									<select
										onChange={handleInputChange}
										className="form-control"
										name="userId"
										value={filters.userId}
										defaultValue={filters.userId}
									>
										{users.map((user) => (
											<option key={user.id} value={user.id}>
												{user.role} - {user.name}
											</option>
										))}
									</select>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group mt-4">
									<h6 className="mb-0 fs-4 mb-2">Desde</h6>
									<input
										type="date"
										className="form-control"
										id="from"
										name="from"
										defaultValue={filters.from}
										value={filters.from}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							<div className="col-3">
								<div className="form-group mt-4">
									<h6 className="mb-0 fs-4 mb-2">Hasta</h6>
									<input
										type="date"
										className="form-control"
										id="to"
										name="to"
										defaultValue={filters.to}
										value={filters.to}
										onChange={handleInputChange}
									/>
								</div>
							</div>
							
						</div>
						<DataTableComponent
							title="Control de caja"
							description="Revisión de las entradas y salidas de caja"
							columns={columnsCashiers}
							data={cashiersData}
							setPerPage={setPerPage}
							loading={loading}
							getData={getCashiersData}
							totalRows={totalRows}
							perPage={perPage}
							model="payment"
						/>

						<TotalsCashiersComponent cashiersData={cashiersData} />
						
						<CashiersCashBoxesDatatable cashiersCashBoxes={cashiersCashBoxes} />
					
					</div>
				</div>
			</div>
		</div>
	)
}
export default CashiersPage
