import useApp from '../utils/useApp'

const HeaderComponent = () => {
	const {
		state: { user },
		actions: { updateUser },
	} = useApp()
	const logOut = () => {
		localStorage.removeItem('user')
		return true
	}
	const updateLastVenue = (venueId, e) => {
		e.preventDefault()
		updateUser({ ...user, lastVenue: venueId })
		return false
	}
	return (
		<header className="app-header rumba-header">
			<nav className="navbar navbar-expand-lg navbar-light">
				<ul className="navbar-nav">
					<li className="nav-item">
						<a className="nav-link sidebartoggler nav-icon-hover ms-n3" id="headerCollapse" href="#nada">
							<i className="ti ti-menu-2"></i>
						</a>
					</li>
					<li className="nav-item d-none d-lg-block">
						<a
							className="nav-link nav-icon-hover"
							href="#nada"
							data-bs-toggle="modal"
							data-bs-target="#exampleModal"
						>
							<i className="ti ti-search"></i>
						</a>
					</li>
				</ul>
                {/*<ul className="navbar-nav quick-links d-none d-lg-flex">
					<li className="nav-item dropdown hover-dd d-none d-lg-block">
						<a className="nav-link" href="#nada" data-bs-toggle="dropdown">
							<img
								src={`https://rumba.cardamomo.com/img/logo-venue-pos-${user.lastVenue}.svg`}
								height="36"
								alt="Main logo"
							/>{' '}
							<span className="mt-1">
								<i className="ti ti-chevron-down"></i>
							</span>
						</a>
						<div className="dropdown-menu dropdown-menu-nav dropdown-menu-animate-up py-0">
							<div className="row">
								<div className="col-8">
									<div className=" ps-7 pt-7">
										<div className="border-bottom">
											<div className="row">
												<div className="col-12">
                                                    <div className="position-relative">
														<a
															onClick={(e) => updateLastVenue(1, e)}
															href="/"
															className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
														>
															<div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
																<img
																	src="https://rumba.cardamomo.com/img/icon-venue-1.png"
																	height="32"
																	width="32"
																	alt="Cardamomo logo"
																/>
															</div>
															<div className="d-inline-block">
																<h6 className="mb-1 fw-semibold bg-hover-primary">
																	Cardamomo
																</h6>
															</div>
														</a>
														<a
															onClick={(e) => updateLastVenue(2, e)}
															href="/"
															className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
														>
															<div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
																<img
																	src="https://rumba.cardamomo.com/img/icon-venue-2.png"
																	height="32"
																	width="32"
																	style={{ objectFit: 'contain' }}
																	alt="1911 logo"
																/>
															</div>
															<div className="d-inline-block">
																<h6 className="mb-1 fw-semibold bg-hover-primary">
																	Tablao 1911
																</h6>
															</div>
														</a>
														<a
															onClick={(e) => updateLastVenue(3, e)}
															href="/"
															className="d-flex align-items-center pb-9 position-relative text-decoration-none text-decoration-none text-decoration-none text-decoration-none"
														>
															<div className="bg-light rounded-1 me-3 p-6 d-flex align-items-center justify-content-center">
																<img
																	src="https://rumba.cardamomo.com/img/icon-venue-3.png"
																	height="32"
																	width="32"
																	style={{ objectFit: 'contain' }}
																	alt="1911 logo"
																/>
															</div>
															<div className="d-inline-block">
																<h6 className="mb-1 fw-semibold bg-hover-primary">
																	Rumba Demo
																</h6>
															</div>
														</a>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</li>
				</ul>*/}
				<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
					<div className="d-flex align-items-center justify-content-between">
						<a
							href="#nada"
							className="nav-link d-flex d-lg-none align-items-center justify-content-center"
							type="button"
							data-bs-toggle="offcanvas"
							data-bs-target="#mobilenavbar"
							aria-controls="offcanvasWithBothOptions"
						>
							<i className="ti ti-align-justified fs-7"></i>
						</a>
						<ul className="navbar-nav flex-row ms-auto align-items-center justify-content-center">
							<li className="nav-item dropdown">
								<a
									className="nav-link nav-icon-hover"
									href="#nada"
									id="drop2"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<i className="ti ti-bell-ringing"></i>
									<div className="notification bg-primary rounded-circle"></div>
								</a>
								<div
									className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
									aria-labelledby="drop2"
								>
									<div className="d-flex align-items-center justify-content-between py-3 px-7">
										<h5 className="mb-0 fs-5 fw-semibold">Notificaciones</h5>
										<span className="badge bg-primary rounded-4 px-3 py-1 lh-sm">5 new</span>
									</div>
									<div className="message-body" data-simplebar>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src={user.image}
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">Roman Joined the Team!</h6>
												<span className="d-block">Congratulate him</span>
											</div>
										</a>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src="/images/profile/user-2.jpg"
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">New message</h6>
												<span className="d-block">Salma sent you new message</span>
											</div>
										</a>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src="/images/profile/user-3.jpg"
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">Bianca sent payment</h6>
												<span className="d-block">Check your earnings</span>
											</div>
										</a>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src="/images/profile/user-4.jpg"
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">Jolly completed tasks</h6>
												<span className="d-block">Assign her new tasks</span>
											</div>
										</a>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src="/images/profile/user-5.jpg"
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">John received payment</h6>
												<span className="d-block">$230 deducted from account</span>
											</div>
										</a>
										<a href="#nada" className="py-6 px-7 d-flex align-items-center dropdown-item">
											<span className="me-3">
												<img
													src={user.image ? user.image : "https://rumba.cardamomo.com/img/neutral.png"}
													alt="user"
													className="rounded-circle"
													width="48"
													height="48"
												/>
											</span>
											<div className="w-75 d-inline-block v-middle">
												<h6 className="mb-1 fw-semibold">Roman Joined the Team!</h6>
												<span className="d-block">Congratulate him</span>
											</div>
										</a>
									</div>
								</div>
							</li>
							<li className="nav-item dropdown">
								<a
									className="nav-link pe-0"
									href="#nada"
									id="drop1"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<div className="d-flex align-items-center">
										<div className="user-profile-img">
											<img
												src={user.image ? user.image : "https://rumba.cardamomo.com/img/neutral.png"}
												className="border border-1 border-primary rounded-circle"
												width="35"
												height="35"
												alt=""
											/>
										</div>
									</div>
								</a>
								<div
									className="dropdown-menu content-dd dropdown-menu-end dropdown-menu-animate-up"
									aria-labelledby="drop1"
								>
									<div className="profile-dropdown position-relative" data-simplebar>
										<div className="py-3 px-7 pb-0">
											<h5 className="mb-0 fs-5 fw-semibold">Perfil de usuario</h5>
										</div>
										<div className="d-flex align-items-center py-9 mx-7 border-bottom">
											<img
												src={user.image ? user.image : "https://rumba.cardamomo.com/img/neutral.png"}
												className="rounded-circle"
												width="80"
												height="80"
												alt=""
											/>
											<div className="ms-3">
												<h5 className="mb-1 fs-3">{user.name}</h5>
												<span className="mb-1 d-block text-dark">{user.role}</span>
												<p className="mb-0 d-flex text-dark align-items-center gap-2">
													<i className="ti ti-mail fs-4"></i> {user.email}
												</p>
											</div>
										</div>

										<div className="d-grid py-4 px-7 pt-8">
											<a onClick={logOut} href="/" className="btn btn-outline-primary">
												Log Out
											</a>
										</div>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</nav>
		</header>
	)
}

export default HeaderComponent
