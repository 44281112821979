import DataTable from 'react-data-table-component'
import { columnsBookingTables } from './../utils/booking-tables-data'

const BookingTablesDatatable = ({ dataTables }) => {
	//const [totalRows, setTotalRows] = useState(0)

	return (
		<div className="mb-4 py-4">
			<div className="row">
				<div className="col-12">
					<h3 className="card-title fs-6 fw-semibold">Mesas</h3>
					{dataTables && <DataTable columns={columnsBookingTables} data={dataTables} />}
				</div>
			</div>
		</div>
	)
}

export default BookingTablesDatatable
