import DataTableComponent from "../components/DataTableComponent"
import { columnsShifts } from "../utils/shifts-data"
import React , { useState } from "react"
import  { getDataApi } from "../services/apiService"
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'


const ShiftsPage = () => {
	const [shiftsData, setShiftsData] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const navigate = useNavigate()

	const { state:{ user }} = useApp()

	const getShiftsData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
		setLoading(true)
		const offset = (page-1) * limit
		const result = await getDataApi(user.lastVenue, 'shift', offset, limit, null, null, sortField, sortDirection, searchTerm)
		const shiftsDataResponse = Object.values(result.data)
		.map((shift) => {
			return {
			id: shift.id,
			name: shift.name,
			time: shift.time,
			type: shift.type,
			hallId: shift.hallId,
			createdAt: shift.createdAt,
			updatedAt: shift.updatedAt,
			}
		})

		setShiftsData(shiftsDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	const handleNew = (e) => {
		const toUrl = `/shift/edit/0`
		navigate(toUrl)
	}

	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<div className="d-sm-flex justify-content-end">
							<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nuevo pase
							</button>
						</div>
						<DataTableComponent
							title="Listado de pases"
							description="Este es el listado de pases del tablao"
							columns={columnsShifts}
							data={shiftsData}
							setPerPage={setPerPage}
							loading={loading}
							getData={getShiftsData}
							totalRows={totalRows}
							perPage={perPage}
							model="shift"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default ShiftsPage
