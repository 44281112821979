

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const numberWithSpaces = (x) => {
	const n = String(x.toFixed(2)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ',' + n.slice(1)
}
const PriceFormatter = ({num}) => {
	return (<span className="price-tag">{num ? numberWithSpaces(num) + '€' : '0'}</span>)
}

const WeekData = ({formData, gm}) => {

    return formData.length > 0 ? (
                <div className='expandable-component weekData'>
                    <ul>
                        { formData
                        .sort((a, b) =>{ 
                            return Number(a[gm]) - Number(b[gm])
                        })
                        .map((bookingSum,i)=>{

                            return (
                                <li key={i}>
                                    <span>{new Date(Number(bookingSum[gm])).toLocaleDateString('es-ES', {day: 'numeric', month: 'short', year: 'numeric'})}</span>
                                    <span>{bookingSum.pax}</span>
                                    <span>{bookingSum.count}</span>
                                    <PriceFormatter num={bookingSum.salesAmount}/>
                                    <PriceFormatter num={bookingSum.aftersalesAmount}/>
                                    <PriceFormatter num={bookingSum.price}/>
                                </li>
                            )
                        })}
                    </ul>
                </div>
    )
    :
    null

}

export default WeekData
