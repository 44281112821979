import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsEvents = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width: '160px'
    },
    {
        name: 'Date',
        selector: row => row.date,
        sortable: true,
        cell: props => props.date ? moment(props.date).format('DD-MM-YYYY HH:mm:ss') : ''
    },
    {
        name: 'Shift Id',
        selector: row => row.shiftId,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow()
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()}
    }
]

const generateKey = () => {
	const arr = [
		'a',
		'b',
		'c',
		'd',
		'e',
		'f',
		'g',
		'h',
		'i',
		'j',
		'k',
		'l',
		'm',
		'n',
		'o',
		'p',
		'q',
		'r',
		's',
		't',
		'u',
		'v',
		'w',
		'x',
		'y',
		'z',
		'A',
		'B',
		'C',
		'D',
		'E',
		'F',
		'G',
		'H',
		'I',
		'J',
		'K',
		'L',
		'M',
		'N',
		'O',
		'P',
		'Q',
		'R',
		'S',
		'T',
		'U',
		'V',
		'W',
		'X',
		'Y',
		'Z',
		'0',
		'1',
		'2',
		'3',
		'4',
		'5',
		'6',
		'7',
		'8',
		'9',
	]
	return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(() => arr[Math.floor(Math.random() * 62)]).join('')
}

export { columnsEvents, generateKey }


