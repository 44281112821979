import DataTableComponent from '../components/DataTableComponent'
import { columnsUsers } from '../utils/users-data'
import { useState } from 'react'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'

const UsersPage = () => {
	const [usersData, setUsersData] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const navigate = useNavigate()
	const {
		state: { user },
	} = useApp()

	const getUsersData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
		setLoading(true)
		const offset = (page - 1) * limit
		const result = await getDataApi(
			user.lastVenue,
			'user',
			offset,
			limit,
			null,
			null,
			sortField,
			sortDirection,
			searchTerm
		)
		const userDataResponse = Object.values(result.data).map((user) => {
			return {
				id: user.id,
				name: user.name,
				role: user.role,
				email: user.email,
				image: user.image,
				status: user.status,
				lastLogin: user.lastLogin,
				createdAt: user.createdAt,
				updatedAt: user.updatedAt,
				pin: user.pin,
				lastVenue: user.lastVenue,
				token: user.token,
			}
		})

		setUsersData(userDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}
	const handleNew = (e) => {
		const toUrl = `/user/edit/0`
		navigate(toUrl)
	}

	return (
		<div className="card">
			<div className="card-body users-list">
				<div className="row">
					<div className="col-12">
						<div className="d-sm-flex justify-content-end">
							<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nuevo usuario
							</button>
						</div>
						<DataTableComponent
							title="Listado de usuarios"
							description="Este es el listado de usuarios"
							columns={columnsUsers}
							data={usersData}
							setPerPage={setPerPage}
							loading={loading}
							getData={getUsersData}
							totalRows={totalRows}
							perPage={perPage}
							model="user"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default UsersPage
