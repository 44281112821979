const NoPage = () => {
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <div className="mb-2">
              <h5 className="mb-0">Error 404</h5>
            </div>
            <p className="card-subtitle mb-3">La página que buscas no existe</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NoPage;
