import DataTableComponent from '../components/DataTableComponent'
import columnsPriceRules from '../utils/price-rules-data'
import { useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { getDataApi } from '../services/apiService'

const getData = (rows = 10) => {

    const now = new Date()

    return Array.from({length: 10}, (_, i) => {

        return {
            id: i+1,
            createdAt: now.toISOString().replace(/T/g, ' '),
            updatedAt: now.toISOString().replace(/T/g, ' '),
            venueId: [0, 1][Math.round(Math.random() * 2)],
            availableFrom: new Date(now.getFullYear(), now.getMonth(), 1).toISOString().replace(/T/g, ' '),
            availableTo: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().replace(/T/g, ' '),
            discount: 10,
            web: 0,
            rumba: 1,
            cumulative: 1,
            daysInAdvance: 2,
            maxHour: 18,
            rules: {
                noDts: [now.toISOString().replace(/T/g, ' '), new Date(now.getFullYear(), now.getMonth(), 10).toISOString().replace(/T/g, ' ')], 
                noEventIds: [1000, 2450, 3000], 
                noWeekDays: [5,6],
                yesShifIds: [1,2,3,7,8,9],
                yesProductIds: []
            }

        }
    })
}
const PriceRulesPage = () => {

    const [data, setData] = useState([])

    const navigate = useNavigate()
    const handleAddNew = () => navigate('/priceRule/edit/0')

    const getPriceRules = async () => {
        let res = await getDataApi(0, 'priceRule')
        if (res.result==='ok') setData(res.data)
        else setData([])
    }

    useEffect(() => {
        getPriceRules()
    }, [])

    return (

        <div className='card'>
            <div className='card-body'>
            <DataTableComponent
                    title="Reglas de fijado de precios"
                    columns={columnsPriceRules}
                    data={data}
                    model="priceRule"
                    sercheable={false}
                    RightSideComponent={() => {
                        return (
                            <button type="button" className="btn rmb-btn-primary maxh-37" onClick={handleAddNew}>
								Nueva Regla
							</button>
                        )
                    }}
                />
            </div>
        </div>

    )
}

export default PriceRulesPage