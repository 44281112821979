import { numberWithSpaces } from "./tracking-data"

const columnsPriceRules = [

    {
		name: 'name',
		selector: (row) => row.id,
        cell: (row) => row.name || row.id,
		sortable: true,
	},

    {
		name: 'web',
		selector: (row) => row.web,
		cell: (row) => row.web === 1 ? 'SI' : 'NO',
		sortable: true,
        width: '100px'
	},
    {
		name: 'rumba',
		selector: (row) => row.rumba,
		cell: (row) => row.rumba === 1 ? 'SI' : 'NO',
		sortable: true,
        width: '100px'
	},
    {
		name: 'cumulative',
		selector: (row) => row.cumulative,
		cell: (row) => row.cumulative === 1 ? 'SI' : 'NO',
		sortable: true,
        width: '100px'
	},

    {
		name: 'discount',
		selector: (row) => row.discount,
		cell: (row) => `${numberWithSpaces(row.discount * (row.discount < 1 ? 100 : 1))}${row.discount < 1 ? '%' : '€'}`,
		sortable: true,
        width: '100px'
	},
   
	{
		name: 'availableFrom',
		selector: (row) => row.availableFrom,
		cell: (row) => new Date(row.availableFrom).toLocaleString('es-ES', {day: '2-digit', month: 'short', year: 'numeric'}),
		sortable: true,
        width: '200px'
	},
	{
		name: 'availableTo',
		selector: (row) => row.availableTo,
		cell: (row) => new Date(row.availableTo).toLocaleString('es-ES', {day: '2-digit', month: 'short', year: 'numeric'}),
		sortable: true,
        width: '200px'
	},
]

export default columnsPriceRules