import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const ModifierForm = ({ data, sendToUpdate, responseMessage }) => {
	const [formData, setFormData] = useState({})
	const [fieldsToSend, setFieldsToSend] = useState([])
	const navigate = useNavigate()

    useEffect(() => {
		setFormData(data)
	}, [data])

    const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldsToSend(new Set([...fieldsToSend, name]))

		setFormData({
			...formData,
			[name]: value,
		})
	}

    const handleSave = async () => {
		const fieldsToSend = new Set(Object.keys(formData))

		if (fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
				.filter((key) => fieldsToSend.has(key))
				.reduce((obj, key) => {
					obj[key] = formData[key]
					return obj
				}, {})
			console.log(dataToSend)
			await sendToUpdate(dataToSend)
		}
	}

    return (
        <form className="mt-4 px-4">
			<div className="d-sm-flex align-items-center justify-content-between mb-3">
				<div>
					<h6 className="mb-0 fs-4 mb-2">Id</h6>
					<div className="form-group">
						<input type="text" className="form-control" id="modifierId" placeholder="Id" defaultValue={formData.id} disabled />
					</div>
				</div>
			</div>
			
			
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Nombre</h6>
				<input type="text" className="form-control" id="modifierName" defaultValue={formData.name} name="name" onChange={handleInputChange} />
			</div>
			<div className="form-group mt-4">
				<h6 className="mb-0 fs-4 mb-2">Precio</h6>
				<input type="text" className="form-control" id="modifierPrice" defaultValue={formData.price} name="price" onChange={handleInputChange} />
			</div>
			<div className="d-flex align-items-right fs-2 mt-4">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
			<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
				Guardar
			</button>

			{responseMessage && (
				<div className="mt-4 alert alert-success" role="alert">
					{responseMessage}
				</div>
			)}
		</form>
    )
}

export default ModifierForm