import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsTables = [
    {
        name: 'Id',
        selector: row => row.id,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width: '100px',
        center: true
    },
    {
        name: 'Pax',
        selector: row => row.pax,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Zone',
        selector: row => row.zone,
        sortable: true,
    },
    {
        name: 'Access',
        selector: row => row.access,
        sortable: true,
    },
    {
        name: 'Style',
        selector: row => row.style,
        sortable: true,
    },
    {
        name: 'X',
        selector: row => row.x,
        sortable: true,
    },
    {
        name: 'Y',
        selector: row => row.y,
        sortable: true,
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow()
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()}
    }
]

const dataTables = [
    {
        id: 1,
        name: '111',
        pax: 4,
        zone: 'zc',
        access: 'Acceso 1',
        style: 'Estilo 1',
        x: 12,
        y: 17,
        createdAt: '2023-04-08 11:28:24',
        updatedAt: '2023-04-08 11:28:241',
        venueId: 1,
    },
    {
        id: 2,
        name: '112',
        pax: 4,
        zone: 'zb',
        access: 'Acceso 2',
        style: 'v',
        x: 29,
        y: 27,
        createdAt: '2023-04-11 11:28:24',
        updatedAt: '2023-04-11 11:28:24',
        venueId: 2,
    }
]

export { columnsTables, dataTables }