const AsideCashierComponent = () => {
	return (
		<aside className="left-sidebar">
			{
				//  Sidebar scroll
			}
			<div>
				<div className="brand-logo d-flex align-items-center justify-content-between">
					<a href="./index.html" className="text-nowrap logo-img">
						<img src="/images/rumba.png" className="dark-logo" width="38" height="38" alt="Logo" />
						<img
							src="/images/rumba.svg"
							className="dark-logo"
							width="60"
							alt="Rumba"
							style={{ marginLeft: 12 }}
						/>
					</a>
					<div className="close-btn d-lg-none d-block sidebartoggler cursor-pointer" id="sidebarCollapse">
						<i className="ti ti-x fs-8"></i>
					</div>
				</div>
				{
					//  Sidebar navigation
				}
				<nav className="sidebar-nav scroll-sidebar" data-simplebar>
					<ul id="sidebarnav">
						<li className="nav-small-cap">
							<i className="ti ti-dots nav-small-cap-icon fs-4"></i>
							<span className="hide-menu">Configuración</span>
						</li>
						<li className="sidebar-item">
							<a className="sidebar-link" href="/cashiers" aria-expanded="false">
								<span>
									<i className="ti ti-building-bank"></i>
								</span>
								<span className="hide-menu">Caja</span>
							</a>
						</li>
					</ul>
					<div className=""></div>
				</nav>
				<div className="fixed-profile p-3 bg-light-secondary rounded sidebar-ad mt-3">
					<div className="hstack gap-3">
						<div className="john-img">
							<img
								src="/images/profile/user-1.jpg"
								className="rounded-circle"
								width="40"
								height="40"
								alt=""
							/>
						</div>
						<div className="john-title">
							<h6 className="mb-0 fs-4 fw-semibold">Mathew</h6>
							<span className="fs-2 text-dark">Designer</span>
						</div>
						<button
							className="border-0 bg-transparent text-primary ms-auto"
							tabIndex="0"
							type="button"
							aria-label="logout"
							data-bs-toggle="tooltip"
							data-bs-placement="top"
							data-bs-title="logout"
						>
							<i className="ti ti-power fs-6"></i>
						</button>
					</div>
				</div>
				{
					//  End Sidebar navigation
				}
			</div>
			{
				//  End Sidebar scroll
			}
		</aside>
	)
}

export default AsideCashierComponent
