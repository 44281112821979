import { useState, useEffect } from 'react'

const TotalsCashiersComponent = ({ cashiersData }) => {
	const resetTotals = {
		'creditcard': 0,
		'credit': 0,
		'cash': 0,
		'paygold': 0,
		'other': 0,
		'webPaypal': 0,
		'webOther': 0,
		'webStripe': 0 ,
		'noPayment': 0,
		'transfer': 0
	}
	const [totals, setTotals] = useState(resetTotals)

	const getTotals = (cashiersData) => {
		cashiersData.forEach((cashier) => {
			const { method, amount } = cashier
			setTotals((prevTotals) => {
				if (prevTotals.hasOwnProperty(method)) {
				  return { ...prevTotals, [method]: prevTotals[method] + amount };
				} else {
				  return prevTotals;
				}
			  })
		})
	}


	useEffect(() => {
		setTotals(resetTotals)
		getTotals(cashiersData)
		// eslint-disable-next-line
	}, [cashiersData])


	return (
		<>
			<div className="row mt-3">
				<h5 class="mb-0">Totales</h5>
				<p class="card-subtitle mt-2">Desglose de totales por método de pago</p>
				{cashiersData.length === 0 && (
					<div className="col-12">
						<p className='my-2 text-center'>No hay datos</p>
					</div>
				)}

				{totals && Object.keys(totals).map((method, i) => {
					return (
						totals[method] > 0 && (
							<div className="col-3 mt-3" key={i}>
								<div className="card">
									<div className="card-body">
										<h6>{method}</h6>
										<p className="card-text">{totals[method].toFixed(2) + ' €' } </p>
									</div>
								</div>
							</div>
						)
					)
				})}

				

			</div>
		</>
	)
}

export default TotalsCashiersComponent