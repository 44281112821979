import DataTableComponent from '../components/DataTableComponent'
import { columnsDevices } from '../utils/devices-data'
import React, { useState } from 'react'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'

const DevicesPage = () => {
	const [dataDevices, setDataDevices] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)

	const {
		state: { user },
	} = useApp()

	const getDevicesData = async (
		page,
		limit = perPage,
		sortDirection = 'desc',
		sortField = 'id',
		searchTerm = null
	) => {
		setLoading(true)
		const offset = (page - 1) * limit
		const result = await getDataApi(
			user.lastVenue,
			'device',
			offset,
			limit,
			null,
			null,
			sortField,
			sortDirection,
			searchTerm
		)
		const devicesDataResponse = Object.values(result.data).map((device) => {
			return {
				id: device.id,
				name: device.name,
				status: device.status,
				identificator: device.identificator,
				location: JSON.stringify(device.location),
				createdAt: device.createdAt,
				updatedAt: device.updatedAt,
			}
		})
		setDataDevices(devicesDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	// const handleNew = (e) => {
	// 	const toUrl = `/device/edit/0`
	// 	navigate(toUrl)
	// }

	return (
		<div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						{/* <div className="d-sm-flex justify-content-end">
							<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nuevo dispositivo
							</button>
						</div> */}
						<DataTableComponent
							title="Listado de dispositivos"
							description="Listado de Ipads del tablao"
							columns={columnsDevices}
							data={dataDevices}
							setPerPage={setPerPage}
							loading={loading}
							getData={getDevicesData}
							totalRows={totalRows}
							perPage={perPage}
							model="device"
						/>
					</div>
				</div>
			</div>
		</div>
	)
}
export default DevicesPage
