//seccion vacía de comisiones, igual que status page
const ComisionsPage = ()=> {
    return (
    <div className="card">
        <div className="card-body">
            <div className="row">
            <div className="col-12">
                <div className="mb-2">
                <h5 className="mb-0">Lo sentimos, esta págna está en construcción.</h5>
                </div>
                <p className="card-subtitle mb-3">La página que buscas no esta disponible en estos momentos.</p>
            </div>
            </div>
        </div>
    </div>
    )
}

export default ComisionsPage