import { useEffect, useState } from 'react'
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const TableForm = ({data, sendToUpdate, responseMessage}) => {
	const [formData, setFormData] = useState({})
	const [fieldsToSend, setFieldsToSend] = useState([])

	useEffect(() => {
		setFormData(data)
	}, [data])

	const handleInputChange = (e) => {
		const { name, value } = e.target
		setFieldsToSend(new Set([...fieldsToSend, name]))
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleSave = () => {
		if(fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
			.filter((key) => fieldsToSend.has(key))
			.reduce((obj, key) => {
				obj[key] = formData[key]
				return obj
			}, {})

			//console.log('dataToSend', dataToSend)
			sendToUpdate(dataToSend)
		}
	}

	return (
		<form className="mt-4 px-4">
			<div className="d-sm-flex align-items-center mb-2">
				<div className='form-group col-4'>
					<h6 className="mb-0 fs-4 mb-2">Id</h6>
					<div className="form-group">
						<input type="text" className="form-control" id="tableId" placeholder="Id" defaultValue={data.id} disabled />
					</div>
				</div>
				<div className="form-group p-3 col-4">
					<h6 className="mb-0 fs-4 mb-2">Nombre</h6>
					<input type="text" className="form-control" name="name" id="tableName" defaultValue={data.name} onChange={handleInputChange} />
				</div>
				<div className="form-group col-4">
					<h6 className="mb-0 fs-4 mb-2">Pax</h6>
					<input type="text" className="form-control" name="pax" id="tablePax" defaultValue={data.pax} onChange={handleInputChange} />
				</div>
			</div>
			
			<div className="d-sm-flex align-items-center mb-2">
				<div className="form-group col-4">
					<h6 className="mb-0 fs-4 mb-2">Zona</h6>
					<input type="text" className="form-control" name="zone" id="tableZone" defaultValue={data.zone} onChange={handleInputChange} />
				</div>
				<div className="form-group p-3 col-4">
					<h6 className="mb-0 fs-4 mb-2">Accesible</h6>
					<input type="text" className="form-control" name="access" id="tableAccess" defaultValue={data.access} onChange={handleInputChange} />
				</div>
				<div className="form-group col-4">
					<h6 className="mb-0 fs-4 mb-2">Estilo</h6>
					<input type="text" className="form-control" name="style" id="tableStyle" defaultValue={data.style} onChange={handleInputChange} />
				</div>
			</div>

			<div className="d-sm-flex align-items-center mb-3">
				<div className="form-group col-4">
					<h6 className="mb-0 fs-4 mb-2">X</h6>
					<input type="text" className="form-control" name="x" id="tableX" defaultValue={data.x} onChange={handleInputChange} />
				</div>
				<div className="form-group col-4 p-3">
					<h6 className="mb-0 fs-4 mb-2">Y</h6>
					<input type="text" className="form-control" name="y" id="tableY" defaultValue={data.y} onChange={handleInputChange} />
				</div>
			</div>
			<div className="d-flex align-items-right fs-2">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
			<button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
				Guardar
			</button>
			{responseMessage && (
				<div className="mt-4 alert alert-success" role="alert">
					{responseMessage}
				</div>
			)}

		</form>
		
	)
}

export default TableForm