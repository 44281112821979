import DataTableComponent from '../components/DataTableComponent'
import { columnsProducts } from '../utils/products-data'
import React, { useState, useEffect } from 'react'
import { getDataApi, getRumbaVars } from '../services/apiService'
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'
import ModifiersComponent from '../parts/ModifiersComponent'

const ProductPage = () => {
	const [dataProduct, setDataProduct] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(100)
	const [productStructure, setProductStructure] = useState([])
	// custom filter for products
	const [prodFilter, setProdFilter] = useState({ fld: '', val: '' })

	const navigate = useNavigate()

	const setupProductFilters = async () => {
		const data = await getRumbaVars()
		data.pos.groups.shift()
		setProductStructure(data.pos.groups)
		//console.log(data.pos.groups)
	}
	useEffect(() => {
		setupProductFilters()
	}, [])

	const {
		state: { user },
	} = useApp()

	const getProductData = async (
		page,
		limit = perPage,
		sortDirection = 'desc',
		sortField = 'id',
		searchTerm = null
	) => {
		setLoading(true)
		const offset = (page - 1) * limit
		const result = await getDataApi(
			user.lastVenue,
			'product',
			offset,
			limit,
			prodFilter.fld,
			prodFilter.val,
			sortField,
			sortDirection,
			searchTerm
		)
		if (!result.data) return
		const ProductDataResponse = Object.values(result.data).map((product) => {
			return {
				id: product.id,
				name: product.name,
				price: product.price,
				category: product.category,
				type: product.type,
				icon: product.icon,
				description: product.description,
				availability: product.availability,
				zone: product.zone,
				group: product.group,
				subgroup: product.subgroup,
				createdAt: product.createdAt,
				updatedAt: product.updatedAt,
			}
		})
		setDataProduct(ProductDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	useEffect(() => {
		getProductData(user.lastVenue, 100, 'desc', 'id', '')
	}, [prodFilter, user.lastVenue])

	const handleFilter = (field, value, e) => {
		e.preventDefault()
		setProdFilter({ fld: field, val: value })
		return false
	}
	const handleNew = (e) => {
		const toUrl = `/product/edit/0`
		navigate(toUrl)
	}
	return (
		<>
			<div className="card">
				<div className="card-body">
					<div className="row">
						<div className="col-12">
							<div className="d-sm-flex justify-content-between">
								<ul className="filters">
									{productStructure &&
										productStructure.map((group) => {
											return (
												<li key={group.group}>
													<button
														className={
															group.group === prodFilter.val
																? 'active filter-link'
																: 'filter-link'
														}
														onClick={(e) => handleFilter('group', group.group, e)}
													>
														{group.group}
													</button>
													<ul className="subfilters">
														{group.subgroups.map((subgroup) => {
															return (
																<li
																	className={
																		subgroup.subgroup === prodFilter.val ? 'active' : ''
																	}
																	key={subgroup.subgroup}
																	onClick={(e) =>
																		handleFilter('subgroup', subgroup.subgroup, e)
																	}
																>
																	{subgroup.subgroup}
																</li>
															)
														})}
													</ul>
												</li>
											)
										})}
								</ul>

								<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
									Nuevo producto
								</button>
							</div>
							<DataTableComponent
								title="Listado de productos"
								description="Listado de productos del tablao"
								columns={columnsProducts}
								data={dataProduct}
								setPerPage={setPerPage}
								loading={loading}
								getData={getProductData}
								totalRows={totalRows}
								perPage={perPage}
								model="product"
							/>
						</div>
					</div>
				</div>
			</div>
			<ModifiersComponent />
		</>
	)
}
export default ProductPage
