import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsModifiers = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Name',
		selector: (row) => row.name,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Price',
		selector: (row) => row.price,
		sortable: true,
		width: '120px',
		center: true,
		cell: (props) => (props.price ? props.price.toFixed(2) + ' €' : '0.00 €'),
	},
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => moment(props.createdAt).fromNow(),
	},
	{
		name: 'Updated At',
		selector: (row) => row.updatedAt,
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
	},
]

export { columnsModifiers }