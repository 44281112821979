import { useState, useEffect } from "react"
import InputDateWrapper from "./dateWrapper"
import InputSelectWrapper from "./selectWrapper"
import Switch from "../Switch"
import { goBackward, goForward, getDefaultDates } from "../../utils/tracking-data"

// CONFIG STRUCTURE
// {
//     page: '',
//     localStorageItem: '', the filters will be stored here, the preferences -> localStorageItem+'-preferences'
//     filtersInterface: {},
//     preferencesInterface: {}
//     rows: [
//         [row 1
//             {ELEMENT (structure below) 1},
//             {ELEMENT 2},
//             [ elements inside a div (only one level is allowed)
//                 {ELEMENT 3},
//                 {ELEMENT 4}
//             ]
//             ...
//         ],
//         [row 2
//           ...
//         ],
//         ...
//     ]
// }

// ELEMENT STRUCTURE:
// {
// 	role:'' * filter / preference / action / layout
// 	id: ''

// 	- - - if filter - - -
// 	key: '' * 
// 	type: '' *
// 	label: ''
// 	- - - if filter & type = select - - -
// 	optionsAndTags: () => [[],[]] *
// 	- option -> {value: 1, name: ''}
// 	- tag -> {values: [1, 2, 3], name: '', icon: 'ti ti-icon-className', active: false}
// 	preferences: []
// 	- 'inputSelectBooleanProp' = true / else false

// 	- - -if preference - - -
// 	key: '' *
// 	type: '' *
// 	label: ''

// 	- - - if action - - -
// 	key: ''
// 	action: () => {} * onClick / onInput etc
// 	type: '' *
// 	inner: ''

// 	//if layout (will be a <p>)
// 	inner: ''
// }

//there are also premade ones: just use {key: 'premade_key'}, theese are: applyFilters, clearFilters, autoFilters

const safeLoad = (obj, localStorageKey) => {

    let item = localStorage.getItem(localStorageKey)
    if (
        !localStorageKey ||
        !item
    )  return obj || {}
    else item = JSON.parse(item)

    if (
        (typeof obj !== 'object' && typeof item !== 'object' && typeof item === typeof obj) ||
        (Array.isArray(obj) && Array.isArray(item))
    ) return item
    else if (
        (typeof obj !== 'object' && typeof item !== 'object' && typeof item !== typeof obj) ||
        (Array.isArray(obj) && !Array.isArray(item)) ||
        (!Array.isArray(obj) && Array.isArray(item))
    ) return obj
    
    const objKeys = Object.keys(obj)
    const itemKeys = Object.keys(item)
    let preloadObj = {}

    for (let key of objKeys) {

        if (!itemKeys.includes(key)) preloadObj[key] = obj[key]
        else preloadObj[key] = item[key]
    }
    return preloadObj
}


const Filters = ({config = {
    rows: [[{
        id: '',
        name: '',
        type: '',
        label: '',
        preferences: {onlyOne: true, autoHide: true , showCurrentValue: true},
        optionsAndTags: () => [[], []],
    }]],
    page: '',
    localStorageItem: '',
    filtersInterface: {},
    preferencesInterface: {}
},
disable = false,
onFilterChange = () => {},
onPreferenceChange = () => {},
}) => {

    const [lock, setLock] = useState(false)
    const [filters, setFilters] = useState(safeLoad(config.filtersInterface, config.localStorageItem))
    const [preferences, setPreferences] = useState(safeLoad(config.preferencesInterface, config.localStorageItem+'-preferences'))
    const [clearFilters, setClearFilters] = useState(0)

    const handleFilters = (e) => {
        let { target: { name, value } } = e
		// value toString
		let isArray = Array.isArray(value)
		if (isArray && typeof value[0] === 'number') {
			value = value.map((x) => x.toString())
		} else if (typeof value === 'number') value.toString()

		setFilters((prev) => {
			if (name.includes('-')) {
				// case of date filter
				let [dateName, i] = name.split('-')
				return {
					...prev,
					[dateName]: i === '1' ? [value, prev[dateName][1]] : [prev[dateName][0], value],
				}
			} else if (name === 'mode'){
                return {...prev, [name]: value, createdAt: getDefaultDates(value, filters.createdAt)}
            } else if (isArray && value.includes(0)) {
				// case of reset filter
				return { ...prev, [name]: [] }
			} else return { ...prev, [name]: value } // normal case
		})
    }

    const handlePreferences = (e) => {
        let {target: { name, value },} = e
        setPreferences({...preferences, [name]: value})
    }

    const cleanFilters = () => {
		setFilters(config.filtersInterface)
		setClearFilters((prev) => prev += 1)
	}

    const handleInner = (filter, i) => {
        return filter.type==='select' ? 
        (<InputSelectWrapper config={filter} filters={filters} onInput={handleFilters} clearFilters={clearFilters} key={i} disable={disable}/>) 
        //date input
        : filter.type==='datetime-local' || filter.type==='date' ?
        (<InputDateWrapper config={filter} filters={filters} setFilters={setFilters} onInput={handleFilters} key={i} disable={disable}/>)

        : filter.key==='autoFilters' ?
        (
        <Switch
            disable={disable}
            name="autoFilters"
            selectId="filters"
            label="Filtros auto"
            defaultVaue={preferences.autoFilters}
            onInput={handlePreferences}
            key={i}
        />
        )
        : filter.type==='switch' ?
        (
        <Switch
            disable={disable}
            name={filter.key}
            selectId={filter.id}
            label={filter.label}
            defaultVaue={filter.role==='preference' ? preferences[filter.key] : filters[filter.key]}
            onInput={filter.role==='preference' ? handlePreferences : handleFilters}
            key={i}
        />
        )
        : filter.key==='clearFilters' ?
        (
            <button onClick={cleanFilters} key={i} className="clear-filters">
                Restablecer<span className="ti ti-arrow-back-up"></span>
            </button>
        )
        : filter.key==='applyFilters' ?
        (
            <button
                className={`${preferences.autoFilters ? 'disable' : ''} apply-filters`}
                onClick={() => onFilterChange(filters)}
                key={i}
            >
                Aplicar filtros
            </button>
        )
        : filter.key==='update' ?
        (
            <button
                className={`${disable ? 'disable' : ''} update-data`}
                onClick={filter.action}
                key={i}
            >
                Actualizar<span className="ti ti-reload"></span>
            </button>
        )
        : filter.key==='download_xls' ?
        (
            <button
                className={`${disable ? 'disable' : ''} download-xls`}
                onClick={filter.action}
                key={i}
            >
                Descargar XLS
            </button>
        )
        : filter.type === 'button' && filter.role === 'action' ?
        (
            <button
                id={filter.id || ''}
                onClick={filter.action}
                key={i}
                dangerouslySetInnerHTML={{__html: filter.inner}}
            >
            </button>
        )
        : filter.role === 'layout' ? 
        (
            <p key={i} className="page_title">{filter.inner}</p>
        )
        : filter.type === 'date_arrows' ?
            <div id={filter.id} className="date_controller" key={i}>
                <p className="info_week">{new Date(filters[filter.key][0]).toLocaleDateString('es-ES',filter.dateOpts)} - {new Date(filters[filter.key][1]).toLocaleDateString('es-ES',filter.dateOpts)}</p>
                <div>
                    <button 
                        className={`${disable ? 'disable' : ''} ti ti-arrow-left icon_transparent_button`} 
                        onClick={() => setFilters({...filters, [filter.key]: goBackward(filters[filter.basedOn], filters[filter.key])})}
                    >
                    </button>
                    <button 
                        className={`${disable ? 'disable' : ''} ti ti-arrow-right icon_transparent_button`} 
                        onClick={() => setFilters({...filters, [filter.key]: goForward(filters[filter.basedOn], filters[filter.key])})}
                    >
                    </button>
                </div>
            </div>
        : null
    }

    useEffect(() => { 
        setLock(true) 
        onFilterChange(filters)
        onPreferenceChange(preferences)
    }, [])

    useEffect(() => {

        if (lock && preferences.autoFilters === 1|| !Object.keys(preferences).includes('autoFilters')) {
            onFilterChange(filters)
        }
        if (config.localStorageItem)localStorage.setItem(config.localStorageItem, JSON.stringify(filters))

    }, [filters])

    useEffect(() => {

        if (lock){
            onPreferenceChange(preferences)
        }
        if (lock && config.localStorageItem) {
            localStorage.setItem(config.localStorageItem+'-preferences', JSON.stringify(preferences))
        }

    }, [preferences])

    return (
        <div className={`page-filters ${config.page}`}>
        {   
            config.rows.map((row, i) => {
                return  (
                    <div className={`r-${i+1} filters_row`} key={i}>
                    {
                        row.map((filter,j) => {
                            return Array.isArray(filter) ?
                            (
                                <div key={j} className="extra_div">
                                {   
                                    filter.map((groupedFilter, k) => handleInner(groupedFilter, k))
                                }
                                </div>
                            )
                            :
                            handleInner(filter, j)
                        })
                    }
                    </div>
                )
            })
        }
        </div>
    )
}

export default Filters