import { useState } from 'react'
import useApp from '../utils/useApp'
import { doLogin } from '../services/apiService'
const LoginPage = () => {
	const {
		state: { token },
		actions: { updateToken, updateUser },
	} = useApp()

	const [user, setUser] = useState('')
	const [password, setPassword] = useState('')
	const [code, setCode] = useState(localStorage.getItem('code') || '')
	const [error, setError] = useState('')
	const [show2FA, setShow2FA] = useState(false)
	const [rememberMe, setRememberMe] = useState(false)

	const handleSubmit = async (e) => {
		e.preventDefault()
		const result = await doLogin(user, password, code, rememberMe)
		if (result.result === 'nok') {
			setError(result.message)
			if (result.request2fa === true) {
				setShow2FA(true)
			} else {
				setShow2FA(false)
				setCode('')
				localStorage.setItem('code', '')
			}
			return
		}
		updateToken(result.token)
		updateUser(result)
		localStorage.setItem('user', JSON.stringify(result))
		localStorage.setItem('token', result.token)
	}

	return (
		<div
			className="page-wrapper"
			id="main-wrapper"
			data-layout="vertical"
			data-navbarbg="skin6"
			data-sidebartype="full"
			data-sidebar-position="fixed"
			data-header-position="fixed"
		>
			<div className="position-relative overflow-hidden radial-gradient min-vh-100 d-flex align-items-center justify-content-center">
				<div className="d-flex align-items-center justify-content-center w-100">
					<div className="row justify-content-center w-100">
						<div className="col-md-8 col-lg-6 col-xxl-3">
							<div className="card mb-0">
								<div className="card-body">
									<a
										href="./index.html"
										className="text-nowrap logo-img text-center d-block mb-5 w-100"
									>
										<img src="/images/rumba.png" height="40" alt="" style={{ marginRight: 16 }} />{' '}
										<img src="/images/rumba.svg" height="40" alt="" />
									</a>

									<form>
										{!show2FA && (
											<>
												<div className="mb-3">
													<label htmlFor="exampleInputEmail1" className="form-label">
														Usuario
													</label>
													<input
														value={user}
														onChange={(e) => setUser(e.target.value)}
														type="email"
														className="form-control"
														id="exampleInputEmail1"
														aria-describedby="emailHelp"
													/>
												</div>
												<div className="mb-4">
													<label htmlFor="exampleInputPassword1" className="form-label">
														Clave
													</label>
													<input
														value={password}
														onChange={(e) => setPassword(e.target.value)}
														type="password"
														className="form-control"
														id="exampleInputPassword1"
													/>
												</div>
											</>
										)}
										{show2FA && (
											<div className="mb-4">
												<label htmlFor="code" className="form-label">
													Código 2FA (recibido en tu email)
												</label>
												<input
													value={code}
													onChange={(e) => { setCode(e.target.value); 
														localStorage.setItem('code', e.target.value) }}
													type="text"
													className="form-control"
													id="code"
												/>
											</div>
										)}
										<div className="d-flex align-items-center justify-content-between mb-4">
											<div className="form-check">
												<input
													className="form-check-input primary"
													type="checkbox"
													value=""
													id="flexCheckChecked"
													checked={rememberMe}
													onChange={(e) => setRememberMe(e.target.checked)}
												/>
												<label
													className="form-check-label text-dark"
													htmlFor="flexCheckChecked"
												>
													Recordar este dispositivo {token}
												</label>
											</div>
											{/*
                      <a className="text-primary fw-medium" href="./authentication-forgot-password.html">¿Has olvidado tu clave?</a>
                      */}
											<p className="error">{error}</p>
										</div>
										<button
											onClick={handleSubmit}
											className="btn btn-primary w-100 py-8 mb-4 rounded-2"
										>
											Acceder
										</button>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LoginPage
