import { useEffect, useState } from 'react';
import { uploadFile } from '../services/apiService';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const VenueForm = ({data, sendToUpdate}) => {
    const [formData, setFormData] = useState({})
    const [fieldsToSend, setFieldsToSend] = useState([])
    const [icon, setIcon] = useState(null)
    const [logo, setLogo] = useState(null)

    const [venueIcon, setVenueIcon] = useState(null);
    const [venueLogo, setVenueLogo] = useState(null);

    useEffect(() => {
        setFormData(data)
        setIcon(data.icon)
        setLogo(data.logo)
    }, [data])

    useEffect(() => {
        if (venueIcon) {
          const file = venueIcon.files[0];
          if (file) {
            uploadAndSetImage(file, 'icon');
          }
        }
    }, [venueIcon])

    useEffect(() => {
        if (venueLogo) {
          const file = venueLogo.files[0];
          if (file) {
            uploadAndSetImage(file, 'logo');
          }
        }
    }, [venueLogo])
    
      const uploadAndSetImage = async (file, field) => {
        try {
          const res = await uploadFile(file, 'users', 'image')
          console.log('res', res)
          if (res.result === 'ok') {
            if(field === 'logo') {
                setLogo(res.data.fullPath)
                setFormData({
                  ...formData,
                  logo: res.data.fullPath,
                })
            }
            if(field === 'icon') {
                setIcon(res.data.fullPath)
                setFormData({
                  ...formData,
                  icon: res.data.fullPath,
                })
            }
          }
        } catch (error) {
          console.log('error', error)
        }
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldsToSend(new Set([...fieldsToSend, name]))

        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSave = () => {
        console.log('formData', formData);
        const fieldsToSend = new Set(Object.keys(formData))

        if(fieldsToSend.size > 0) {
            const dataToSend = Object.keys(formData)
            .filter((key) => fieldsToSend.has(key))
            .reduce((obj, key) => {
                obj[key] = formData[key];
                return obj;
            }, {})

            sendToUpdate(dataToSend)
        }
    }


    return (
        <form className="mt-4 px-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <div className='form-group col-3'>
                    <h6 className="mb-0 fs-4 mb-2">Id</h6>
                    <div className="form-group">
                        <input type="text" className="form-control" id="venueId" placeholder="Id" defaultValue={formData.id} disabled />
                    </div>
                </div>
                <div className='form-group col-4'>
                    <h6 className="mb-0 fs-4 mb-2">Estado</h6>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input success"
                            type="radio"
                            id="activeStatus"
                            checked={formData.status === "active"} 
                            value="active"
                            onChange={handleInputChange}
                            name='status'
                        />
                        <label className="form-check-label" htmlFor="activeStatus">Active</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input danger"
                            type="radio"
                            id="inactiveStatus"
                            checked={formData.status === "inactive"} 
                            value="inactive"
                            onChange={handleInputChange}
                            name='status'
                        />
                        <label className="form-check-label" htmlFor="inactiveStatus">Inactive</label>
                    </div>
                </div>
                {logo && 
                    <div className='col-4'>
                        <div className="d-flex align-items-center justify-content-center mb-2 venue-icon-image">
                            <div className="img-bg d-flex align-items-center justify-content-center">
                                <div className="d-flex align-items-center justify-content-center overflow-hidden">
                                    <img src={logo} alt="" className="w-100 h-100" />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Cambiar Logo</h6>
                <input type="file" onChange={(e) => setVenueLogo(e.target)} name='logo' className="form-control form-control-file" id="logoVenue" />
            </div>
            
            
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Nombre</h6>
                <input type="text" className="form-control" id="venueName" name="name" defaultValue={formData.name} onChange={handleInputChange}/>
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Colores</h6>
                <input type="text" className="form-control" id="venueColors" name="colors" defaultValue={formData.colors && JSON.stringify(formData.colors)} onChange={handleInputChange}/>
            </div>

            {icon && 
                 <div className="form-group">
                    <h6 className="mb-0 mt-4 fs-4 mb-2">Icono</h6>
                    <div className="d-flex  mb-2 venue-icon-image">
                        <div className="img-bg d-flex align-items-center justify-content-center">
                            <div className="d-flex align-items-center justify-content-center overflow-hidden">
                                <img src={icon} alt="" className="w-100 h-100" />
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Cambiar Icono</h6>
                <input type="file" onChange={(e) => setVenueIcon(e.target)} name='icon' className="form-control form-control-file" id="iconVenue" />
            </div>

            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Escenario</h6>
                <input type="text" className="form-control" id="venueStage" name="stage" defaultValue={formData.stage && JSON.stringify(formData.stage)} onChange={handleInputChange} />
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Mapa</h6>
                <input type="text" className="form-control" id="venueMap" name="map" defaultValue={formData.map} onChange={handleInputChange}/>
            </div>
            <div className="d-flex align-items-right fs-2 mt-4">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
            <button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
                Guardar
            </button>
            
        </form>
        
    )
}

export default VenueForm;