import useApp from '../utils/useApp'
import { useEffect, useState } from 'react'
import { getDashboardApi } from '../services/apiService'

const DashboardComponent = () => {
	const {
		state: { user },
	} = useApp()
	const [dashboardData, setDashboardData] = useState(null)

	useEffect(() => {
		getDashboardData()
		// eslint-disable-next-line
	}, [])

	const getDashboardData = async () => {
		const from = new Date().toISOString().slice(0, 10)
		const to = new Date().toISOString().slice(0, 10)
		const result = await getDashboardApi(user.lastVenue, from, to)
		result.totalSales = result.salesData.reduce((acc, sale) => acc + sale.price, 0)
		result.pax = result.salesData.reduce((acc, sale) => acc + sale.pax, 0)
		result.dinners = result.salesData.reduce((acc, sale) => acc + sale.meals, 0)
		result.bookings = result.salesData.length
		setDashboardData(result)
	}

	const parseDate = (date) => {
		const dateJs = new Date(date)
		if (dateJs instanceof Date && !isNaN(dateJs)) {
			return dateJs.toLocaleDateString('es-ES', {
				weekday: 'short',
				year: 'numeric',
				month: 'short',
				day: 'numeric',
			})
		} else return ''
	}
	const parsePrice = (price) => {
		if (!isNaN(parseFloat(price))) {
			return parseFloat(price).toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
		} else return ''
	}

	return (
		<div className="card">
			{dashboardData && (
				<>
					<div className="card-body">
						<div className="row pb-4">
							<div className="col-lg-4 d-flex align-items-stretch">
								<div className="d-flex flex-column align-items-start w-100">
									<div className="text-start">
										<h5 className="card-title fw-semibold">Informe de ventas</h5>
										<span>
											{parseDate(dashboardData.fromDate)} - {parseDate(dashboardData.toDate)}
										</span>
									</div>
									<div className="mt-lg-auto mt-4 mb-4">
										<span className="text-dark">
											Ventas totales <span className="text-success">+9.78%</span>
										</span>
										<h2 className="mt-2 fw-bold">{parsePrice(dashboardData.totalSales)}</h2>
										<span>Incremento del 15% desde el mes pasado</span>
									</div>
								</div>
							</div>
							<div className="col-lg-8 d-flex align-items-stretch">
								<div className="w-100">
									<div className="d-md-flex align-items-start gap-3">
										<div>
											<h6 className="mb-0">Ocupación de la sala</h6>
											<div className="d-flex align-items-center gap-3">
												<h2 className="mt-2 fw-bold">75%</h2>
												<span className="badge bg-primary  px-2 py-1 d-flex align-items-center">
													<i className="ti ti-chevron-down fs-4"></i>2.8%{' '}
												</span>
											</div>
										</div>
										<div className="ms-auto">
											<select className="form-select">
												<option value="1">Marzo 2023</option>
												<option value="2">Abril 2023</option>
												<option value="3">Mayo 2023</option>
												<option value="4">Junio 2023</option>
											</select>
										</div>
									</div>
									<div className="mt-4">
										<div id="financial"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="border-top">
						<div className="row gx-0">
							<div className="col-md-4 border-end">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-danger mb-0">
										<span className="text-danger">
											<span className="round-8 bg-danger rounded-circle d-inline-block me-1"></span>
										</span>
										Entradas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.pax}</h3>
								</div>
							</div>
							<div className="col-md-4 border-end">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-primary mb-0">
										<span className="text-primary">
											<span className="round-8 bg-primary rounded-circle d-inline-block me-1"></span>
										</span>
										Cenas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.dinners}</h3>
								</div>
							</div>
							<div className="col-md-4">
								<div className="p-4 py-3 py-md-4">
									<p className="fs-4 text-info mb-0">
										<span className="text-info">
											<span className="round-8 bg-info rounded-circle d-inline-block me-1"></span>
										</span>
										Reservas
									</p>
									<h3 className=" mt-2 mb-0">{dashboardData.bookings}</h3>
								</div>
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	)
}
export default DashboardComponent
