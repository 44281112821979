

import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const References = ({formData}) => {

    return (
                <div className='expandable-component'>
                    { formData.length > 1 &&
                        <ul>
                            { formData.map((origin,i)=>{
                                if (i===0) return
                                return (
                                    <li onClick={()=>{
                                        const editUrl = `/origin/edit/${origin.id}`
			                            window.location.href = editUrl
                                    }}>
                                        <span>{origin.id}</span>
                                        <span>{origin.name.length>23 ? origin.name.substr(0,20)+'...' : origin.name}</span>
                                        <span>{origin.parent}</span>
                                        <span>{origin.order}</span>
                                        <span>{origin.venueId}</span>
                                        <span>{moment(origin.createdAt).fromNow()}</span>
                                        <span>{moment(origin.updatedAt).fromNow()}</span>
                                    </li>
                                )
                            })}
                        </ul>
                    }

                    {formData.length === 1 &&
                        <div className='alert alert-warning'>
                            No hay líneas de reserva
                        </div>
                    }
                </div>
    )

}

export default References
