import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsOrigins = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '75px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true
    },
    {
        name: 'Parent',
        selector: row => row.parent,
        sortable: true,
        width: '75px',
    },
    {
        name: 'Order',
        selector: row => row.order,
        sortable: true,
        width: '75px',
    },
    {
        name: 'Venue Id',
        selector: row => row.venueId,
        sortable: true,
        width: '75px',
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow(),
        width: '140px',
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()},
        width: '140px',
    }
    
]

export { columnsOrigins }


