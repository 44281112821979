import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsPayments = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Method',
        selector: row => row.method,
        sortable: true
    },
    {
        name: 'Type',
        selector: row => row.type,
        cell: props => {
            if (props.type === 'total') {
                return <span className="badge bg-light-success text-success">{props.type}</span>
            } else {
                return <span className="badge bg-light-warning text-warning">{props.type}</span>
            }
        },
        sortable: true,
        width: '100px'
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Cashier',
        selector: row => row.cashier,
        sortable: true
    },
    {
        name: 'User Id',
        selector: row => row.userId,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Booking Id',
        selector: row => row.bookingId,
        sortable: true
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow()
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()}
    }
]

export { columnsPayments }


