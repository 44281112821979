import { BrowserRouter, Routes, Route } from 'react-router-dom'
import AppProvider from './utils/appProvider'
import useApp from './utils/useApp'
import HeaderComponent from './parts/HeaderComponent'
import AsideComponent from './parts/AsideComponent'
import AsideSimpleComponent from './parts/AsideSimpleComponent'
import AsideCashierComponent from './parts/AsideCashierComponent'
import './App.css'
import HomePage from './pages/HomePage'
import LoginPage from './pages/LoginPage'
import BookingsPage from './pages/BookingsPage'
import BookingsLinesPage from './pages/BookingsLinesPage'
import EventsPage from './pages/EventsPage'
// salas
import PaymentsPage from './pages/PaymentsPage'
// Caja
// Factura
import ProductsPage from './pages/ProductsPage'
import UsersPage from './pages/UsersPage'
import ShiftsPage from './pages/ShiftsPage'
import TablesPage from './pages/TablesPage'
import DevicesPage from './pages/DevicesPage'
import OriginsPage from './pages/OriginsPage'
import VenuesPage from './pages/VenuesPage'
import LogsPage from './pages/LogsPage'
import TrackingPage from './pages/TrackingPage'
import EditPage from './pages/EditPage'
import NoPage from './pages/NoPage'
import CashiersPage from './pages/CashiersPage'
import StatusPage from './pages/StatusPage'
import ComisionsPage from './pages/ComisionsPage'
import PriceRulesPage from './pages/PriceRulesPage'
import { useEffect } from 'react'

function ReadContext() {
	const {
		state: { token, user },
		actions: { updateToken, updateUser },
	} = useApp()

	useEffect(() => {
		const user = localStorage.getItem('user')
		if (user) {
			const userJson = JSON.parse(user)
			if (userJson) {
				updateToken(userJson.token)
				updateUser(userJson)
			}
		}
	}, [updateToken, updateUser])
	//console.log(token)
	return (
		<>
			{token === '' || token === undefined || token === null ? (
				<LoginPage />
			) : (
				<BrowserRouter>
					{user.role === 'admin' ? <AsideComponent /> : user.role === 'cashier' ? <AsideCashierComponent /> : <AsideSimpleComponent />}
					<div className="body-wrapper">
						<HeaderComponent />
						<div className="container-fluid">
							<Routes>
								<Route path="/" element={user.role === 'admin' ? <HomePage /> : user.role === 'cashier' ? <CashiersPage /> :<ProductsPage />} />
								<Route path="/sales" element={<BookingsPage />} />
								<Route path="/bookings-lines" element={<BookingsLinesPage />} />
								<Route path="/events" element={<EventsPage />} />
								<Route path="/status" element={<StatusPage />} />
								<Route path="/payments" element={<PaymentsPage />} />
								{/* Caja */}
								{/* facturas */}
								<Route path="/comisions" element={<ComisionsPage/>} />
								<Route path="/products" element={<ProductsPage />} />
								<Route path="/users" element={<UsersPage />} />
								<Route path="/shifts" element={<ShiftsPage />} />
								<Route path="/tables" element={<TablesPage />} />
								<Route path="/devices" element={<DevicesPage />} />
								<Route path="/origins" element={<OriginsPage />} />
								<Route path="/venues" element={<VenuesPage />} />
								<Route path="/logs" element={<LogsPage />} />
								<Route path="/trackings" element={<TrackingPage />} />
								<Route path="/cashiers" element={<CashiersPage />} />
								<Route path="/price-rules" element={<PriceRulesPage />} />
								<Route path="/:model/edit/:id" element={<EditPage />} />
								<Route path="*" element={<NoPage />} />
							</Routes>
						</div>
					</div>
				</BrowserRouter>
			)}
		</>
	)
}

function App() {
	return (
		<div className="App">
			{
				//  Preloader
				/*

    <div className="preloader">
      <img src="/images/logos/favicon.png" alt="loader" className="lds-ripple img-fluid" />
    </div>

      */
			}
			<div
				className="page-wrapper"
				id="main-wrapper"
				data-layout="vertical"
				data-navbarbg="skin6"
				data-sidebartype="full"
				data-sidebar-position="fixed"
				data-header-position="fixed"
			>
				<AppProvider>
					<ReadContext />
				</AppProvider>
			</div>

			{
				//  Search Bar
			}

			<div className="modal fade" id="exampleModal" tabIndex="-1" aria-hidden="true">
				<div className="modal-dialog modal-dialog-scrollable modal-lg">
					<div className="modal-content rounded-1">
						<div className="modal-header border-bottom">
							<input type="search" className="form-control fs-3" placeholder="Search here" id="search" />
							<span data-bs-dismiss="modal" className="lh-1 cursor-pointer">
								<i className="ti ti-x fs-5 ms-3"></i>
							</span>
						</div>
						<div className="modal-body message-body" data-simplebar="">
							<h5 className="mb-0 fs-5 p-1">Búsqueda</h5>
							<ul className="list mb-0 py-2">
								<li className="p-1 mb-1 bg-hover-light-black">
									<a href="/">
										<span className="fs-3 text-black fw-normal d-block">Modern</span>
										<span className="fs-3 text-muted d-block">/dashboards/dashboard1</span>
									</a>
								</li>
								<li className="p-1 mb-1 bg-hover-light-black">
									<a href="/">
										<span className="fs-3 text-black fw-normal d-block">Dashboard</span>
										<span className="fs-3 text-muted d-block">/dashboards/dashboard2</span>
									</a>
								</li>
								<li className="p-1 mb-1 bg-hover-light-black">
									<a href="/">
										<span className="fs-3 text-black fw-normal d-block">Contacts</span>
										<span className="fs-3 text-muted d-block">/apps/contacts</span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default App
