import { useState } from 'react'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import DataTableComponent from '../components/DataTableComponent'
import { columnsPayments } from './../utils/payments-data'



const BookingPayments = ({bookingId}) => {

    const { state:{ user }} = useApp();
    const [loading, setLoading] = useState(true)
    const [totalRows, setTotalRows] = useState(0)
    const [perPage, setPerPage] = useState(10)
    const [dataBookingPayments, setDataBookingPayments] = useState([])

    const getBookingPaymentsData = async (page, limit = perPage) => {
        const offset = (page - 1) * perPage
                
        const result = await getDataApi(user.lastVenue, 'payment', offset, limit, 'bookingId', bookingId)
        const bookingPaymentsDataResponse = Object.values(result.data)
        //.filter((bookingPayment) => bookingPayment.bookingId === bookingId)
        .map((bookingPayment) => {
            return {
                id: bookingPayment.id,
                method: bookingPayment.method,
                type: bookingPayment.type,
                amount: bookingPayment.amount,
                cashier: bookingPayment.cashier,
                userId: bookingPayment.userId,
                bookingId: bookingPayment.bookingId,
                createdAt: bookingPayment.createdAt,
                updatedAt: bookingPayment.updatedAt
            }
        }).slice(0, 5)

        setDataBookingPayments(bookingPaymentsDataResponse)
        setTotalRows(result.count)
        setLoading(false)
    }

    
    return (
        <div className=" mb-4 px-5 py-4">
            <div className="row">
                <div className="col-12">
                    <h3 className='card-title fs-6 fw-semibold'>Pagos</h3>
                    { BookingPayments && bookingId &&
                        <DataTableComponent
                            title=""
                            description=""
                            columns={columnsPayments}
                            data={dataBookingPayments}
                            setPerPage={setPerPage}
                            loading={loading}
                            getData={getBookingPaymentsData}
                            totalRows={totalRows}
                            perPage={perPage}
                            model="payment"
                        />
                    }

                    {dataBookingPayments.length === 0 &&
                        <div className='alert alert-warning'>
                            No hay líneas de reserva
                        </div>
                    }

                </div>
            </div>
            
        </div>
        
    )

}

export default BookingPayments