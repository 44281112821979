import { useCallback, useState } from 'react';
import AppContext from './appContext';
function AppProvider({ children }) {
  
    const [user, setUser] = useState({});
    const [token, setToken] = useState('');

    const updateToken = useCallback( (token) => { setToken(token) }, []);
    const updateUser = useCallback( (user) => { 
        setUser(user)
        localStorage.setItem("user", JSON.stringify(user));
    }, []);

    const value = { state: { user, token }, actions: { updateToken, updateUser } };

  return (
    <AppContext.Provider value={value}>
      {children}
    </AppContext.Provider>
  )
}
export default AppProvider