import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsCashbox = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Status',
        selector: row => row.status,
        cell: props => {
            if (props.type === 'open') {
                return <span className="badge bg-light-success text-success">{props.status}</span>
            } else {
                return <span className="badge bg-light-warning text-warning">{props.status}</span>
            }
        },
        sortable: true,
        width: '100px'
    },
    {
        name: 'Cash Amount',
        selector: row => row.cashAmount,
        sortable: true,
        width: '100px',
        cell: props => props.cashAmount ? props.cashAmount.toFixed(2) + ' €' : ''
    },
    {
        name: 'Cards Amount',
        selector: row => row.cardsAmount,
        sortable: true,
        width: '100px',
        cell: props => props.cardsAmount ? props.cardsAmount.toFixed(2) + ' €' : ''
    },
    {
        name: 'Balance',
        selector: row => row.balance,
        sortable: true,
        cell: props => props.balance ? props.balance.toFixed(2) + ' €' : ''
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).format('DD/MM/YYYY HH:mm:ss')
    }
]

export { columnsCashbox }


