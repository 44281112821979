import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useApp from '../utils/useApp'
import { getDataApi } from '../services/apiService'
import DataTableComponent from '../components/DataTableComponent'
import { columnsModifiers } from '../utils/modifiers-data'

const ModifiersComponent = () => {

	const [dataModifier, setDataModifier] = useState([])
	const [loading, setLoading] = useState(false)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(100)

	const navigate = useNavigate()

	const {
		state: { user },
	} = useApp()

	const getModifierData = async (
		page,
		limit = perPage,
		sortDirection = 'asc',
		sortField = 'name',
		searchTerm = null
	) => {
		setLoading(true)
		const offset = (page - 1) * limit
		const result = await getDataApi(
			user.lastVenue,
			'modifier',
			offset,
			limit,
			null,
			null,
			sortField,
			sortDirection,
			searchTerm
		)
		if (result.data) {
            const ModifierDataResponse = Object.values(result.data).map((modifier) => {
                return {
                    id: modifier.id,
                    name: modifier.name,
                    price: modifier.price,
                    createdAt: modifier.createdAt,
                    updatedAt: modifier.updatedAt,
                }
            })
            setDataModifier(ModifierDataResponse)
            setTotalRows(result.count)
            setLoading(false)
        }
	}

	useEffect(() => {
		getModifierData(1, 100, 'asc', 'name', '')
	}, [user.lastVenue])

    const handleNew = (e) => {
		const toUrl = `/modifier/edit/0`
		navigate(toUrl)
	}

    return (
        <div className="card">
			<div className="card-body">
				<div className="row">
					<div className="col-12">
						<div className="d-sm-flex justify-content-end">
                            <button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nuevo modificador
							</button>
                        </div>
                        {
                            dataModifier && 
                                <DataTableComponent
                                    title="Listado de modificadores"
                                    description="Listado de modificadores de los productos"
                                    columns={columnsModifiers}
                                    data={dataModifier}
                                    setPerPage={setPerPage}
                                    loading={loading}
                                    getData={getModifierData}
                                    totalRows={totalRows}
                                    perPage={perPage}
                                    model="modifier"
                                />
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModifiersComponent