import DataTableComponent from "../components/DataTableComponent";
import { columnsVenues } from "../utils/venues-data";
import React, { useState } from 'react';
import { getDataApi } from "../services/apiService";
import useApp from '../utils/useApp';


const VenuePage = () => {
  const [dataVenue, setDataVenue] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10);

  const { state:{ user }} = useApp();

  const getVenuesData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
    setLoading(true)
    const offset = (page-1) * limit
    const result = await getDataApi(user.lastVenue, 'venue', offset, limit, null, null, sortField, sortDirection, searchTerm)
    const VenuesDataResponse = Object.values(result.data)
    .map((venue) => {
      return {
        id: venue.id,
        name: venue.name,
        logo: venue.logo,
        colors: JSON.stringify(venue.colors),
        status: venue.status,
        createdAt: venue.createdAt,
        updatedAt: venue.updatedAt,
        icon: venue.icon,
        state: venue.state,
        map: venue.map,
      }
    })
    setDataVenue(VenuesDataResponse)
    setTotalRows(result.count)
    setLoading(false)
  }
  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <DataTableComponent
                title="Listado de tablaos"
                description=""
                columns={columnsVenues}
                data={dataVenue}
                setPerPage={setPerPage}
                loading={loading}
                getData={getVenuesData}
                totalRows={totalRows}
                perPage={perPage}
                model="venue"
                customStyles={{rowHeight:'60px'}}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default VenuePage;
