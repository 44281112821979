import { useState } from 'react'
import { getDataApi } from '../services/apiService'
import useApp from '../utils/useApp'
import DataTableComponent from '../components/DataTableComponent'
import { columnsBookingsLines } from './../utils/booking-lines-data'

const BookingLinesDatatable = ({ bookingId }) => {
	//console.log('bookingId', bookingId)
	const {
		state: { user },
	} = useApp()
	const [loading, setLoading] = useState(true)
	const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const [dataBookingLines, setDataBookingLines] = useState([])

	const getBookingLinesData = async (page, limit = perPage) => {
		const offset = (page - 1) * perPage
		const result = await getDataApi(user.lastVenue, 'bookingLine', offset, limit, 'bookingId', bookingId)
		const bookingLinesDataResponse = Object.values(result.data)
			.map((bookingLine) => {
				return {
					id: bookingLine.id,
					code: bookingLine.code,
					desc: bookingLine.desc,
					type: bookingLine.type,
					category: bookingLine.category,
					zone: bookingLine.zone,
					qty: bookingLine.qty,
					price: bookingLine.price,
					disc: bookingLine.disc,
					bookingId: bookingLine.bookingId,
					createdAt: bookingLine.createdAt,
					updatedAt: bookingLine.updatedAt,
					productId: bookingLine.productId,
				}
			})
			.slice(0, 5)

		setDataBookingLines(bookingLinesDataResponse)
		setTotalRows(result.count)
		setLoading(false)
	}

	return (
		<div className="mb-4 px-5 py-4">
			<div className="row">
				<div className="col-12">
					<h3 className="card-title fs-6 fw-semibold">Líneas de reserva</h3>

					{dataBookingLines && bookingId && (
						<DataTableComponent
							title=""
							description=""
							columns={columnsBookingsLines}
							data={dataBookingLines}
							setPerPage={setPerPage}
							loading={loading}
							getData={getBookingLinesData}
							totalRows={totalRows}
							perPage={perPage}
							model="bookingLine"
						/>
					)}
					{dataBookingLines.length === 0 && (
						<div className="alert alert-warning">No hay líneas de reserva</div>
					)}
				</div>
			</div>
		</div>
	)
}

export default BookingLinesDatatable
