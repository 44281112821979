
const BookingPaymentInfo = ({ bookingPaymentInfo }) => {
        return (
            
            
            <div className='booking-payment-info mb-4 px-5'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Información del pago</h3>
                <div className="d-sm-flex align-items-center mb-3">
                    <div className="form-group mt-4 col-6">
                        <h6 className="mb-0 fs-4 mb-2">Price</h6>
                        <input type="text" className="form-control" id="bookingPrice" defaultValue={bookingPaymentInfo.price && bookingPaymentInfo.price.toFixed(2) + ' €'} readOnly />
                    </div>
                </div>
                <div className="form-group mt-4">
                    <h6 className="fs-4 mb-3">Payment Status</h6>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input success"
                            type="radio"
                            name="payment-status"
                            id="paidStatus"
                            defaultValue="paid"
                            checked={bookingPaymentInfo.paymentStatus === "paid"}
                            readOnly
                        />
                        <label className="form-check-label" htmlFor="paidStatus">Paid</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input warning"
                            type="radio"
                            name="payment-status"
                            id="micePendingStatus"
                            defaultValue="mice_pending"
                            checked={bookingPaymentInfo.paymentStatus === "mice_pending"}
                            readOnly
                        />
                        <label className="form-check-label" htmlFor="micePendingStatus">Mice Pending</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input secondary"
                            type="radio"
                            name="payment-status"
                            id="pendingStatus"
                            defaultValue="pending"
                            checked={bookingPaymentInfo.paymentStatus === "pending"}
                            readOnly
                        />
                        <label className="form-check-label" htmlFor="pendingStatus">Pending</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input danger"
                            type="radio"
                            name="payment-status"
                            id="refundedStatus"
                            defaultValue="refunded"
                            checked={bookingPaymentInfo.paymentStatus === "refunded"} 
                            readOnly
                        />
                        <label className="form-check-label" htmlFor="refundedStatus">Refunded</label>
                    </div>
                </div>
            </div>
        )
}

export default BookingPaymentInfo
