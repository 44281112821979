import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const imgPath = 'https://rumba.cardamomo.com/img/products/'

const columnsProducts = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Name',
		selector: (row) => row.name,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Price',
		selector: (row) => row.price,
		sortable: true,
		width: '120px',
		center: true,
		cell: (props) => (props.price ? props.price.toFixed(2) + ' €' : ''),
	},
	{
		name: 'Type',
		selector: (row) => row.type,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Group',
		selector: (row) => row.group,
		sortable: true,
	},
	{
		name: 'SubGroup',
		selector: (row) => row.subgroup,
		sortable: true,
	},
	{
		name: 'Icon',
		selector: (row) => row.icon,
		width: '100px',
		center: true,
		cell: (props) => (
			<img src={props.icon ? imgPath + props.icon : '/images/placeholder/no-image.png'} width={60} alt="Icon" className="icon-image" />
		),
	},
	{
		name: 'Description',
		selector: (row) => row.description,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Availability',
		selector: (row) => row.availability,
		sortable: true,
	},
	{
		name: 'Zone',
		selector: (row) => row.zone,
		sortable: true,
	},
	{
		name: 'Category',
		selector: (row) => row.category,
		sortable: true,
		width: '160px',
		center: true,
	},
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => moment(props.createdAt).fromNow(),
	},
	{
		name: 'Updated At',
		selector: (row) => row.updatedAt,
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
	},
]

export { columnsProducts }
