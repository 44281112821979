import React, { useState } from 'react'

const Switch = ({
    onInput = (e)=>{},
    name = '',
    selectId = '',
    disable = false,
    label = '',
    labelPos = '',
    defaultVaue = 0
}) => {
    
    const [value, setValue] = useState(Boolean(defaultVaue) ? 1 : 0)

    const handleInputEvent = (setter) => {

        let newValueState = null
        if (typeof setter === 'function') {
            newValueState = setter(value)
        } else newValueState = setter

        setValue(newValueState)

        onInput({
            target : {
                name: name || 'input-select',
                value: newValueState
            }
        })

    }

    return (
        <div className={`switch-container ${disable ? 'disable' : ''}`} id={selectId || 'input-select'} style={{flexDirection: `${labelPos.length>0 ? labelPos : 'row'}`}}>
            {label.length>0 && (<p className='label'>{label}</p>)}
            <div className={`switch ${value===1 ? 'on' : 'off'}`} onClick={()=>{
                handleInputEvent((prev)=>prev === 0 ? 1 : 0) 
            }} >
                <span></span>
            </div>
        </div>
    )
}
export default Switch
