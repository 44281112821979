import SalesDensitySquare from "./SalesDensitySquare"
import { useEffect, useState } from "react"
import InputSelect from "./InputSelect/InputSelect"
import Switch from "./Switch"
import SpinnerComponent from "../parts/SpinnerComponent"
// possible functions:
// hue coloritation √
// dinamic tables (day - hour) ¿?
// selectable coloritation √
// find maxes √

const styleValues = [
    {value: 0, name: "Escala de verdes"},
    {value: 1, name: "Escala de negros"},
    {value: 2, name: "Mapa de calor"}
]
const colorizedByValues = [
    {value: 0, name: "Nº reservas"},
    {value: 1, name: "Ganancias"},
    {value: 2, name: "Personas"}
]

const SalesDensity = ({
    data, 
    show, 
    loading, 
    propsToConcat = [
        {iconClassName: '', propName: '', colorizedByName: '', visibleText: ''}
    ],
    onLoad = () => {}
}) => {

    const test = propsToConcat.map(({colorizedByName},i) => {
        return {value: i, name: colorizedByName}
    })
    
    const [printableData,setPrintableData] = useState({})
    const [colorizedByOpts, setcolorizedByOpts] = useState(test)
    const [styleOpts, setStyleOpts] = useState(styleValues)
    const [colorizedBy, setColorizedBy] = useState(1) // 0 qty | 1 € | 2 pax
    const [style, setStyle] = useState(0)
    const [findMaxes, setFindMaxes] = useState(false)

    const normalizeData = () => {
        // let newObj = {maxQty: 0, maxMny: 0, maxPax: 0}
        let newObj = {}
        propsToConcat.forEach(({propName}) => newObj['max_'+propName] = 0)
        
        data.forEach((item) => {
            const fecha = 
            new Date(item.createdAt)
            .toLocaleString('es',{weekday: 'short', hour: '2-digit'})
            .split(", ").join("")//dom23
            // newObj[fecha] = newObj[fecha] || { total: 0, sales: 0, pax: 0 };
            if (!newObj[fecha]) {
                newObj[fecha] = {}
                propsToConcat.forEach(({propName}) => newObj[fecha][propName] = 0)
            }
            // newObj[fecha].total += item.price;
            // newObj[fecha].pax += Number(item.pax)
            // newObj[fecha].sales+= item.bookings;
            propsToConcat.forEach(({propName}) => newObj[fecha][propName] += Number(item[propName]))

            // newObj['maxQty'] = Math.max(newObj['maxQty'], newObj[fecha].sales);
            // newObj['maxMny'] = Math.max(newObj['maxMny'], newObj[fecha].total);
            // newObj['maxPax'] = Math.max(newObj['maxPax'], newObj[fecha].pax);
            propsToConcat.forEach(({propName}) => 
                newObj['max_'+propName] = Math.max(newObj['max_'+propName], newObj[fecha][propName])
            )
        });
        setPrintableData(newObj)
        onLoad()
    }

    const onInput = (e) => {
        e.target.name === 'colorized-by' ?
        setColorizedBy(e.target.value)
        :
        setStyle(e.target.value)
    }

    useEffect(()=>{
        if (data) { normalizeData() }
    },[data])

    const days = ["row","lun","mar","mié","jue","vie","sáb","dom"]
    const hours = ["col","00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"]

    return (
        <div className={`sls-dty ${!show ? "display-none" : "" }`}>
            <div className="sls-dty-header">
                <div>
                    <h5>Densidad de ventas</h5>
                    <p className="card-subtitle mt-2">Datos agrupados por número de ventas.</p>
                </div>
                <div>
                    <Switch 
                        name="show-maxes"
                        selectId="switch-maxes"
                        label="Mostrar máximos"
                        onInput={(e)=>{setFindMaxes(e.target.value)}}
                    />
                    <InputSelect
                        name="colorized-by"
                        selectId="slct-colorized-by"
                        options={colorizedByOpts}
                        setOptions={setcolorizedByOpts}
                        defaultValue={0}
                        onlyOne={true}
                        showCurrentValue={true}
                        onInput={onInput}
                    />
                    <InputSelect
                        name="style"
                        selectId="slct-style"
                        options={styleOpts}
                        setOptions={setStyleOpts}
                        defaultValue={0}
                        onlyOne={true}
                        showCurrentValue={true}
                        onInput={onInput}
                    />
                </div>
            </div>
            <p className="legend"><span></span>Max ganancias.<span></span>Max Personas.<span></span>Max reservas.</p>
            <div className="sls-dty-tb">
                <>
                {loading && ( <div className="loading">
                    <span className="loader"></span>
                </div>)}
                { days.map((day,eje_y)=>{
                    return (
                        <div className="sls-dty-tb-r" key={eje_y}>
                            { hours.map((hour,eje_x)=>{
                                return (

                                    <SalesDensitySquare 
                                    key={eje_y+eje_x}
                                    eje_x={eje_x}
                                    eje_y={eje_y}
                                    cell={day+hour} //dom23
                                    sales={printableData}
                                    colorizedBy={colorizedBy}
                                    findMaxes={findMaxes}
                                    style={style}
                                    propsToConcat={propsToConcat}
                                    />

                                )
                            })}
                        </div>
                    )
                })}
                </>
            </div>
        </div>
    )
}
export default SalesDensity
