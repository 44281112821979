import DataTableComponent from "../components/DataTableComponent";
import { columnsLogs } from "../utils/logs-data";
import React, { useState } from 'react';
import { getDataApi } from "../services/apiService";
import useApp from '../utils/useApp';


const LogPage = () => {
  const [dataLog, setDataLog] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10);

  const { state:{ user }} = useApp();

  const getLogsData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
    setLoading(true)
    const offset = (page-1) * limit
    const result = await getDataApi(user.lastVenue, 'log', offset, limit, null, null, sortField, sortDirection, searchTerm)
    // console.log(result.data)
    const LogsDataResponse = Object.values(result.data)
    .map((log) => {
      return {
        id: log.id,
        type: log.type,
        ip: log.ip,
        userId: log.userId,
        deviceId: log.deviceId,
        action: JSON.stringify(log.action),
        createdAt: log.createdAt,
        updatedAt: log.updatedAt,
        bookingId: log.bookingId,
      }
    })
    setDataLog(LogsDataResponse)
    setTotalRows(result.count)
    setLoading(false)
  }
  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <DataTableComponent
                title="Listado de Log"
                description="Listado de logs de Rumba"
                columns={columnsLogs}
                data={dataLog}
                setPerPage={setPerPage}
                loading={loading}
                getData={getLogsData}
                totalRows={totalRows}
                perPage={perPage}
                model="log"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default LogPage;
