import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsDevices = [
	{
		name: 'id',
		selector: (row) => row.id,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Name',
		selector: (row) => row.name,
		sortable: true,
	},
	{
		name: 'Status',
		selector: (row) => row.status,
		sortable: true,
		width: '100px',
	},
	{
		name: 'Location',
		selector: (row) => row.location,
		sortable: true,
		width: '200px',
	},
	{
		name: 'Identificator',
		selector: (row) => row.identificator,
	},
	{
		name: 'Version',
		selector: (row) => row.version,
		sortable: true,
		width: '120px',
	},
	{
		name: 'Created At',
		selector: (row) => row.createdAt,
		sortable: true,
		cell: (props) => moment(props.createdAt).fromNow(),
		width: '200px',
	},
	{
		name: 'Updated At',
		selector: (row) => row.updatedAt,
		sortable: true,
		cell: (props) => {
			return moment(props.updatedAt).fromNow()
		},
		width: '200px',
	},
]

const dataDevices = [
	{
		id: 1,
		name: 'Device 1',
		version: '1.0.0',
		identificator: '123456789',
		status: 'Active',
		location: 'Location 1',
		createdAt: '2021-01-01',
		updatedAt: '2021-01-01',
	},
	{
		id: 2,
		name: 'Device 2',
		version: '1.0.0',
		identificator: '123456789',
		status: 'Active',
		location: 'Location 2',
		createdAt: '2021-01-01',
		updatedAt: '2021-01-01',
	},
]

export { columnsDevices, dataDevices }
