import { useEffect, useState } from 'react';
import moment from 'moment'
import 'moment/locale/es'
import methods from '../utils/paymentMethods';
moment.locale('es')

const PaymentForm = ({data, sendToUpdate}) => {
    const [formData, setFormData] = useState({})
    const [fieldsToSend, setFieldsToSend] = useState([])

    useEffect(() => {
        setFormData(data)
    }, [data])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldsToSend(new Set([...fieldsToSend, name]))
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSave = () => {
        if(fieldsToSend.size > 0) {
            const dataToSend = Object.keys(formData)
            .filter((key) => fieldsToSend.has(key))
            .reduce((obj, key) => {
                obj[key] = formData[key];
                return obj;
            }, {})

            //console.log('dataToSend', dataToSend)
            sendToUpdate(dataToSend)
        }
    }

    const handleTextareaResize = () => {
        const textarea = document.getElementById("paymentObservations")
        textarea.style.height = "auto"
        textarea.style.height = (textarea.scrollHeight + 2) + 'px';
    }

    return (
        <form className="mt-4 px-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <div className="form-group">
                    <h6 className="mb-0 fs-4 mb-2">Id</h6>
                    <div className="form-group">
                        <input type="text" className="form-control" id="paymentId" placeholder="Id" defaultValue={formData.id} disabled />
                    </div>
                </div>
            </div>

            
            <div className="d-sm-flex align-items-center mb-3">
                <div className="form-group mt-4 col-4">
                    <h6 className="mb-0 fs-4 mb-2">Método</h6>
                    <select onChange={handleInputChange} className="form-control" name="method" value={formData.method}>
						{
                            
                            methods.paymentMethods.map((x) => {
								return <option key={x.code} value={x.code}>{x.name}</option>
							})
						}
					</select>
                </div>
                <div className="form-group mt-4 col-4 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Tipo</h6>
                    <input type="text" className="form-control" id="paymentType" name="type"  defaultValue={formData.type} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-4 col-4">
                    <h6 className="mb-0 fs-4 mb-2">Importe</h6>
                    <input type="text" className="form-control" id="paymentAmount" name="amount" defaultValue={formData.amount} onChange={handleInputChange} />
                </div>
            </div>

            <div className="d-sm-flex  mb-3">
                <div className="form-group mt-2 col-4 py-2">
                    <h6 className="mb-0 fs-4 mb-2">Cajero</h6>
                    <input type="text" className="form-control" id="paymentCashier" name="cashier" defaultValue={formData.cashier} onChange={handleInputChange} />
                </div>
                <div className="form-group mt-2 col-4 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Id Usuario</h6>
                    <div className="d-sm-flex flex-dir-col">
                        <input type="text" className="form-control" id="paymentUserId" name="userId" defaultValue={formData.userId} onChange={handleInputChange} />
                        <a href={"/user/edit/" + formData.userId} aria-expanded="false" alt="Ver usuario" className='pf-uid-href'>
                            <span className="d-sm-flex align-items-center py-2">
                                <i className="ti ti-link fs-7"></i>
                                Ver usuario
                            </span>
                        </a>
                    </div>
                </div>
                <div className="form-group mt-2 col-4 py-2">
                    <h6 className="mb-0 fs-4 mb-2">Id Reserva</h6>
                    <div className="d-sm-flex flex-dir-col">
                        <input type="text" className="form-control" id="paymentBookingId" name="bookingId" defaultValue={formData.bookingId} onChange={handleInputChange} />
                        <a href={"/booking/edit/" + formData.bookingId} aria-expanded="false" alt="Ver reserva">
                            <span className="d-sm-flex align-items-center py-2">
                                <i className="ti ti-link fs-7"></i>
                                Ver reserva
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <div className="d-sm-flex mb-3 lst-rw-form">
                <div className="form-group mt-2 col-4 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Observaciones</h6>
                    <textarea type="textarea" className="form-control textarea" id="paymentObservations" name="obs" defaultValue={formData.obs} onChange={handleInputChange} onInput={handleTextareaResize}/>
                </div>
            </div>
            <div className="d-sm-flex mb-3 lst-rw-form">
                <div className="form-group mt-2 col-4 p-2">
                    <h6 className="mb-0 fs-4 mb-2">Auditoría</h6>
                    <input type="text" className="form-control" id="PaymentAudit" name="audit" defaultValue={formData.audit} disabled readOnly/>
                </div>
            </div>
            <div className="d-flex align-items-right fs-2">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
            <button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
                Guardar
            </button>
        </form>
        
    )
}

export default PaymentForm;