import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsVenues = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width: '120px',
    },
    {
        name: 'Icon',
        selector: row => row.icon,
        center: true,
        width: '100px',
        cell: props => (
            <img
              src={props.icon}
              width={60}
              alt='Icon'
            />
          )
    },
    {
        name: 'Logo',
        selector: row => row.logo,
        sortable: true,
        width: '200px',
        style: {
            backgroundColor: '#111',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        cell: props => (
            <img
              src={props.logo}
              width={60}
              alt='Icon'
              className='venues-logos-scaled'
            />
          )
    },
    {
        name: 'Colors',
        selector: row => row.colors,
        sortable: true,
        width: '200px',
    },
    {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).fromNow(),
        width: '180px',
    },
    {
        name: 'Updated At',
        selector: row => row.updatedAt,
        sortable: true,
        cell: props => {return moment(props.updatedAt).fromNow()},
        width: '180px',
    },
    {
        name: 'Stage',
        selector: row => row.stage,
        width: '120px',
    },
    {
        name: 'Map',
        selector: row => row.map,
        width: '200px',
    }

]

export { columnsVenues }


