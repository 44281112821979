import { createContext  } from 'react'

const AppContext = createContext({
  state:{
    user:{
      id: 0,
      name: '',
      image: '',
      lastVenue: 1
    },
    token: '',
  },
  actions:{}
})

export default AppContext