import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsBookingTables = [
    {
        name: 'Id',
        selector: row => row.id,
        sortable: true,
        width: '100px',
    },
    {
        name: 'Name',
        selector: row => row.name,
        sortable: true,
        width: '200px',
        center: true
    },
    {
        name: 'Zone',
        selector: row => row.zone,
        sortable: true,
    }
]


export { columnsBookingTables }