import DataTableComponent from "../components/DataTableComponent"
import { columnsTables } from "../utils/tables-data"
import React , { useState } from "react"
import  { getDataApi } from "../services/apiService"
import useApp from '../utils/useApp'
import { useNavigate } from 'react-router-dom'

const TablesPage = () => {
  const [tablesData, setTablesData] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(10)
	const navigate = useNavigate()


  const { state:{ user }} = useApp()

  const getTablesData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
    setLoading(true)
    const offset = (page-1) * limit
    const result = await getDataApi(user.lastVenue, 'table', offset, limit, null, null, sortField, sortDirection, searchTerm)
    const tablesDataResponse = Object.values(result.data)
    .map((table) => {
      return {
        id: table.id,
        name: table.name,
        pax: table.pax,
        zone: table.zone,
        access: table.access,
        style: table.style,
        x: table.x,
        y: table.y,
        createdAt: table.createdAt,
        updatedAt: table.updatedAt
      }
    })

    setTablesData(tablesDataResponse)
    setTotalRows(result.count)
    setLoading(false)
  }

  const handleNew = (e) => {
		const toUrl = `/table/edit/0`
		navigate(toUrl)
	}

  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
						<div className="d-sm-flex justify-content-end">
							<button type="button" className="btn rmb-btn-primary" onClick={handleNew}>
								Nueva mesa
							</button>
						</div>
            <DataTableComponent
                title="Listado de mesas"
                description="Este es el listado de mesas del tablao"
                columns={columnsTables}
                data={tablesData}
                setPerPage={setPerPage}
                loading={loading}
                getData={getTablesData}
                totalRows={totalRows}
                perPage={perPage}
                model="table"
                />
          </div>
        </div>
      </div>
    </div>
  )
}
export default TablesPage
