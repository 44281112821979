import { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import { columnsCashbox } from './../utils/cashbox-data'

const CashiersCashBoxesDatatable = ({ cashiersCashBoxes }) => {

	const [loading, setLoading] = useState(true)
	const [dataCashbox, setDataCashbox] = useState([])	

	const loadCashiersCashBoxesData = (cashiersCashBoxes) => {
		setLoading(true)

		const cashesDataResponse = cashiersCashBoxes && cashiersCashBoxes.map((cashbox) => {
			return {
				id: cashbox.id,
				status: cashbox.status,
				cashAmount: cashbox.cashAmount,
				cardsAmount: cashbox.cardsAmount,
				balance: cashbox.balance,
				createdAt: cashbox.createdAt,
			}
		})

		setDataCashbox(cashesDataResponse)

		setLoading(false)
	}

	useEffect(() => {
		loadCashiersCashBoxesData()
		// eslint-disable-next-line
	}, [])

	return (
		<div className=" mb-4 py-4">
			<div className="row">
				<div className="col-12">
					<h5 class="mb-0">Apertura / Cierre de cajas</h5>
					<p class="card-subtitle mt-2">Desglose de aperturas y cierres de caja por usuario</p>
					{ dataCashbox &&
						<DataTable
							columns={columnsCashbox}
							data={dataCashbox}
							progressPending={loading}
							pagination
							paginationServer
						/>
					}

					{ !dataCashbox &&
						<p className='my-2 text-center'>
							No hay aperturas / cierres de caja
						</p>
						}
				</div>
			</div>
		</div>
	)
}

export default CashiersCashBoxesDatatable