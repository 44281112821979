import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import Switch from '../components/Switch';
import { shifts } from '../utils/bookings-data';
import { generateKey } from '../utils/events-data';
import { getDataApi, getLiveContentData, updateModelById } from '../services/apiService';

const timeDelay = 280

let defaultSchedule = {
	"mode": 2,
	"blocks": [
		{
			"ar": 0,
			"id": "b",
			"pa": 1,
			"fix": true,
			"team": []
		},
		{
			"ar": 0,
			"id": "d",
			"pa": 2,
			"fix": true
		}
	],
	"current": 0,
	"eventId": 0
}
let defaultFormData = {
	"id": 0,
	"name": "",
	"date": "",
	"shiftId": 0,
	"venueId": 0,
	"schedule": defaultSchedule,
	"rumbaOpen": 1,
	"webOpen": 1,
	"phoneRequired": 0,
	"zvOpen": 1,
	"zaOpen": 1,
	"za2Open": 1,
	"zbOpen": 1,
	"zcOpen": 1,
}

let scheduleHasChange = false
let contentRequested = false

const EventForm = ({data, sendToUpdate, responseMessage }) => {

	const [formData, setFormData] = useState(defaultFormData)
	const [occupation, setOccupation] = useState({zv: {qty: 0, max: 1},za: {qty: 0, max: 1},zb: {qty: 0, max: 1},zc: {qty: 0, max: 1}})
	const [fieldsToSend, setFieldsToSend] = useState([])
	const [content, setContent] = useState({palos: [],artists: []})

	const getContent = async () => {

		if (contentRequested) return
		contentRequested = true
		const result = await getLiveContentData()
		const newContent = {
			palos: [...result.palos.sort((a, b) => a.data.name.localeCompare(b.data.name)).map((pa) => ({value: Number(pa.data.wpId), name: pa.data.name}))],
			artists: [...result.artists.sort((a, b) => a.data.name.localeCompare(b.data.name)).map((ar) => ({value: Number(ar.data.wpId), name: ar.data.name})), {value: 0, name: 'Cuadro flamenco completo'}]
		}
		setContent(newContent)
	}

	const getOccupation = async (event) => {
		const result = await getDataApi(event.venueId, 'booking', 0, 1000, null, null, 'id', 'asc', null, {
			venueId: event.venueId,
			eventId: event.id,
			metrics: 'zone',
		})
		if (result.result === 'ok') {
			setOccupation((prev) => {
				let newState = {...prev}
				newState.zv = {qty: Number(result.data.find(({zone}) => zone==='zv')?.tickets || 0), max: Number(result.pax.find(({zone}) => zone==='zv')?.capacity || 0)}
				newState.za = {qty: Number(result.data.find(({zone}) => zone==='za')?.tickets || 0), max: Number(result.pax.find(({zone}) => zone==='za')?.capacity || 0)}
				newState.zb = {qty: Number(result.data.find(({zone}) => zone==='zb')?.tickets || 0), max: Number(result.pax.find(({zone}) => zone==='zb')?.capacity || 0)}
				newState.zc = {qty: Number(result.data.find(({zone}) => zone==='zc')?.tickets || 0), max: Number(result.pax.find(({zone}) => zone==='zc')?.capacity || 0)}
				return newState
			})
			
		}
	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFieldsToSend(new Set([...fieldsToSend, name])) 
		setFormData({
			...formData,
			[name]: value,
		})
	}

	const handleUp = (e, index) => {
		e.preventDefault()
		scheduleHasChange = true
		const toMove = document.getElementsByClassName('schedule_list_li')[index]
		const superior = document.getElementsByClassName('schedule_list_li')[index - 1]

		if (superior && toMove && !formData.schedule.blocks[index - 1].fix) {

			superior.classList.add('below')
			toMove.classList.add('above')
			setTimeout(() => {
				toMove.classList.remove('above')
				superior.classList.remove('below')
				arrayMove(index, index - 1)
			}, 300)
		}
	}

	const handleDown = (e, index) => {
		e.preventDefault()
		scheduleHasChange = true
		const toMove = document.getElementsByClassName('schedule_list_li')[index]
		const inferior = document.getElementsByClassName('schedule_list_li')[index + 1]

		if (inferior && toMove && index + 1 < formData.schedule.blocks.length && !formData.schedule.blocks[index + 1].fix) {
			inferior.classList.add('inferior')
			toMove.classList.add('below')
			setTimeout(() => {
				toMove.classList.remove('below')
				inferior.classList.remove('inferior')
				arrayMove(index, index + 1)
			}, 300)
		}
	}

	const handleDelete = (e, index, blockId) => {

		e.preventDefault()
		scheduleHasChange = true
		const inferiores = Array.from(document.getElementsByClassName('schedule_list_li')).splice(index + 1)
		const toDelete = document.getElementsByClassName('schedule_list_li')[index]
		toDelete.classList.add('deleted')
		setTimeout(() => {
			inferiores.forEach((inferior, i) => {
				inferior.classList.add('inferior')
			})
		}, timeDelay)

		setTimeout(() => {
			setFormData({
				...formData,
				schedule: {...formData.schedule, blocks: formData.schedule.blocks.filter(({id}) => blockId !== id)}
			})
			toDelete.style.display = 'none'
			inferiores.forEach((inferior) => {
				inferior.classList.remove('inferior')
			})
		}, timeDelay * 2)
	}

	const handleAddNew = (e) => {
		e.preventDefault()
		scheduleHasChange = true
		let lastElements = formData.schedule.blocks[formData.schedule.blocks.length - 1].fix ? 
			Array.from(document.getElementsByClassName('schedule_list_li')).splice(formData.schedule.blocks.length - 1) : 
			Array.from(document.getElementsByClassName('schedule_list_li')).splice(formData.schedule.blocks.length)
		lastElements.forEach((el, i) => {
			el.classList.add('inferior_add')
		})
		setTimeout(() => {
			setFormData((prev) => {
				let newState = {...prev}
				let blocks = [...newState.schedule.blocks]
				blocks[blocks.length - 1].fix ?
					blocks.splice(blocks.length - 1, 0, {ar: -1, pa: -1, fix: false, id: generateKey()})
					:
					blocks.push({ar: -1, pa: -1, fix: false, id: generateKey()})
				newState.schedule.blocks = blocks
				return newState
			})
			lastElements.forEach((el) => {
				el.classList.remove('inferior_add')
			})
		}, timeDelay * 2)
	}

	const arrayMove = (oldIndex, newIndex) => {
		setFormData((prev) => {
			const newSate = {...prev}
			const [movedElement] = newSate.schedule.blocks.splice(oldIndex, 1)
			newSate.schedule.blocks.splice(newIndex, 0, movedElement)
			return newSate
		})
	}

	const handleSave = async () => {
		if(fieldsToSend.size > 0) {
			const dataToSend = Object.keys(formData)
			.filter((key) => fieldsToSend.has(key))
			.reduce((obj, key) => {
				obj[key] = formData[key];
				return obj;
			}, {})
			// console.log(dataToSend)
			sendToUpdate(dataToSend)
		}
	}

	const updateScheduleInDb = async (d) => await updateModelById(d.venueId, 'event', d.id, {schedule: {...d.schedule, eventId: d.id}})

	useEffect(() => {
		if (data.id > 1) {
			
			if (data.schedule && data.schedule.eventId===0) {
				//if event exists and its schedule has eventId 0 updates schedule eventId here & in db
				updateScheduleInDb(data)
				setFormData({...data, schedule: {...data.schedule, eventId: data.id}})
			}
			else if (!data.schedule){
				//if event exists and schedule is null, set defaultSchedule with eventId
				scheduleHasChange = true
				setFormData({...data, schedule: {...formData.schedule, eventId: data.id}})

			}
			else setFormData(data)

			getOccupation(data)
		}
		getContent()
	}, [data])

	useEffect(() => {
		console.log('ok', scheduleHasChange)
		if (!scheduleHasChange ) return
		handleInputChange({target: {name: 'schedule', value: formData.schedule}})
	}, [JSON.stringify(formData.schedule)])

	return (
		<form className='event_form'>
			<div>
				<div className='left_side'>
					<div className='input_group'>
						<div>
							<p className="label">Tablao</p>
							<select name='venueId' value={formData.venueId || 0} onChange={handleInputChange}>
								<option value={0} disabled> Seleccionar tablao</option>
								<option value={1}> cardamomo</option>
								<option value={2}> Villarosa 1911</option>
							</select>
						</div>
					</div>
					<div className='input_group'>
						<div>
							<p className="label">Id</p>
							<input type="text" className="form-control" id="eventId" placeholder="Id" value={formData.id} disabled onChange={()=>{/* prevents the error trigger */}} />
						</div>
						<div>
							<p className="label">Nombre</p>
							<input type="text" className="form-control" id="eventName" name="name" defaultValue={formData.name} onChange={handleInputChange} />
						</div>
					</div>
					<div className='input_group'>
						<div>
							<p className="label">Pase (Shift Id)</p>
							<select name='shiftId' value={formData.shiftId || 0} onChange={handleInputChange}>
								<option value={0} disabled> Selecciona un pase</option>
								{ shifts
								.sort((a, b) => a.name.localeCompare(b.name))
								.map(({name, value}) => 
									(Number(formData.venueId)===1 && name.includes('cardamomo')) || (Number(formData.venueId)===2 && name.includes('1911')) ? 
										<option value={value} key={value}>{name.split('-')[0]}</option> :
									Number(formData.venueId)!==1 && Number(formData.venueId)!==2 ? 
										<option value={value} key={value}>{name}</option> : null
								)

								}
							</select>
						</div>
						<div>
							<p className="label">Fecha</p>
							<input
								type='datetime-local'
								className="form-control" 
								value={formData.date ? formData.date.substring(0, 16) : ''}
								onChange={(e) => {
									// let parsedDate = new Date(e.target.value).toLocaleDateString('es-ES',{year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'})
									// let [date, time] = parsedDate.split(',')
									// let [day, month, year, h, m, s, ms] = [...date.split('/').map((s) => Number(s)), ...time.split(':').map((s) => Number(s)), 0, 0]
									handleInputChange({target: {name: 'date', value: e.target.value + ':00.000Z'}})
								}}
							/>
						</div>
					</div>
					<div className='switch_wrapper z_v'>
						<Switch defaultVaue={formData.zvOpen} name='zvOpen' label='Venta zona Vip' onInput={handleInputChange}/>
						<div className='occupation_graphic'>
							<span style={{width: `${Math.min(100, occupation.zv.qty / occupation.zv.max * 100)}%`}}></span>
						</div>
						<span>{`${(occupation.zv.qty / occupation.zv.max * 100).toFixed(2)}% Ocupado`}</span>
					</div>
					<div className='switch_wrapper z_a'>
						<Switch defaultVaue={formData.zaOpen} name='zaOpen' label='Venta zona A' onInput={handleInputChange}/>
						<div className='occupation_graphic'>
							<span style={{width: `${Math.min(100, occupation.za.qty / occupation.za.max * 100)}%`}}></span>
						</div>
						<span>{`${(occupation.za.qty / occupation.za.max * 100).toFixed(2)}% Ocupado`}</span>
					</div>
					<div className='switch_wrapper z_b'>
						<Switch defaultVaue={formData.zbOpen} name='zbOpen' label='Venta zona B' onInput={handleInputChange}/>
						<div className='occupation_graphic'>
							<span style={{width: `${Math.min(100, occupation.zb.qty / occupation.zb.max * 100)}%`}}></span>
						</div>
						<span>{`${(occupation.zb.qty / occupation.zb.max * 100).toFixed(2)}% Ocupado`}</span>
					</div>
					<div className='switch_wrapper z_c'>
						<Switch defaultVaue={formData.zcOpen} name='zcOpen' label='Venta zona C' onInput={handleInputChange}/>
						<div className='occupation_graphic'>
							<span style={{width: `${Math.min(100, occupation.zc.qty / occupation.zc.max * 100)}%`}}></span>
						</div>
						<span>{`${(occupation.zc.qty / occupation.zc.max * 100).toFixed(2)}% Ocupado`}</span>
					</div>
				</div>
				<div className='right_side'>
					<p className='label'>Schedule</p>
					<div className='schedule_container'>
						<ul id='schedule_list'>
							{
							formData.schedule.blocks.map((palo, i) => {
								return (
									<li key={palo.id} className='schedule_list_li'>
										<div className='controls_left'>
											<button 
												className={`ti ti-pin${palo.fix ? '-filled fixed' : ' no_fixed'}`}
												onClick={(e) => {
													e.preventDefault()
													scheduleHasChange = true
													setFormData((prev) => {
														let newState = {...prev}
														newState.schedule.blocks[i].fix = !newState.schedule.blocks[i].fix
														return newState
													})	
												}}
											>
											</button>
											<button 
												className='ti ti-trash delete'
												onClick={(e) => handleDelete(e, i, palo.id)}
											>
											</button>
										</div>

										<div className='palo_info'>
											<select className='pa' value={Number(palo.pa)} onChange={(e) => setFormData((prev) => {
												let newState = {...prev}
												newState.schedule.blocks[i].pa = e.target.value
												return newState
											})}>
												<option disabled value={-1}>Selecciona un palo</option>
												{content.palos && (
													<> 
													{
													content.palos.map(({value, name}, j) => {
														return (
															<option value={value} key={`pa-${value}`}>{name}</option>
														)
													})
													}
													</>
												)}
											</select>

											<select className='ar' value={Number(palo.ar)} onChange={(e) => setFormData((prev) => {
												let newState = {...prev}
												newState.schedule.blocks[i].ar = e.target.value
												return newState
											})}>
												<option disabled value={-1}>Selecciona un artista</option>
												{content.artists && (
													<>
													{
													content.artists.map(({value, name}, j) => {
														return (
															<option value={value} key={`ar-${value}`}>{name}</option>
														)
													})
													}
													</>
												)}
											</select>
										</div>

										<div className='controls_right'>
											<button 
												className={`ti ti-arrow-up ${palo.fix ? 'disable' : ''}`}
												onClick={(e) => handleUp(e, i)}
											>
											</button>
											<button 
												className={`ti ti-arrow-down ${palo.fix ? 'disable' : ''}`}
												onClick={(e) => handleDown(e, i)}
											>
											</button>
										</div>
									</li>
								)
							})
							}
							<li className='add_palo_li schedule_list_li'>
								<button 
									className='ti ti-plus add_palo' 
									onClick={handleAddNew}
								>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
				<div className='update_info'>
					Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}
				</div>
				<button type="button" className="btn rmb-btn-primary mt-3" onClick={handleSave}>
					Guardar
				</button>
				{responseMessage && (
					<div className="mt-4 alert alert-success" role="alert">
						{responseMessage}
					</div>
				)}
		</form>
		
	)
}

export default EventForm;