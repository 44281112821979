import DataTableComponent from "../components/DataTableComponent";
import { columnsPayments } from "../utils/payments-data";
import React, { useState } from 'react';
import { getDataApi } from "../services/apiService";
import useApp from '../utils/useApp';


const PaymentPage = () => {
  const [dataPayment, setDataPayment] = useState([])
  const [loading, setLoading] = useState(false)
  const [totalRows, setTotalRows] = useState(0)
	const [perPage, setPerPage] = useState(20);

  const { state:{ user }} = useApp();

  const getPaymentData = async (page, limit = perPage, sortDirection = 'desc', sortField = 'id', searchTerm = null) => {
    setLoading(true)
    const offset = (page-1) * limit
    const result = await getDataApi(user.lastVenue, 'payment', offset, limit, null, null, sortField, sortDirection, searchTerm)
    const PaymentDataResponse = Object.values(result.data)
    .map((payment) => {
      return {
        id: payment.id,
        method: payment.method,
        type: payment.type,
        amount: payment.amount,
        cashier: payment.cashier,
        userId: payment.userId,
        bookingId: payment.bookingId,
        createdAt: payment.createdAt,
        updatedAt: payment.updatedAt
      }
    })
    setDataPayment(PaymentDataResponse)
    setTotalRows(result.count)
    setLoading(false)
  }
  
  return (
    <div className="card">
      <div className="card-body">
        <div className="row">
          <div className="col-12">
            <DataTableComponent
                title="Listado de pagos"
                description="Listado de pagos tablao"
                columns={columnsPayments}
                data={dataPayment}
                setPerPage={setPerPage}
                loading={loading}
                getData={getPaymentData}
                totalRows={totalRows}
                perPage={perPage}
                model="payment"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
export default PaymentPage;
