import moment from 'moment'
import { getOptions } from '../services/apiService'
import 'moment/locale/es'
moment.locale('es')
const numberWithSpaces = (x, f = 2) => {
	const n = String(Number(x || 0).toFixed(f)).split('.')
	return n[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.') + (f === 0 ? '' : ',' + n.slice(1))
}

const columnsComputedTracking = [
	{
		name: 'source',
		selector: (row) => row.source,
		cell: (row) => row.source === '' ? 'Ninguno' : row.source ?? '-',
		sortable: true,
	},
	{
		name: 'medium',
		selector: (row) => row.medium,
		cell: (row) => row.medium === '' ? 'Ninguno' : row.medium ?? '-',
		sortable: true,
	},
	{
		name: 'campaign',
		selector: (row) => row.campaign,
		cell: (row) => row.campaign === '' ? 'Ninguno' : row.campaign ?? '-',
		sortable: true,
	},
	{
		name: 'group',
		selector: (row) => row.group,
		cell: (row) => row.group === '' ? 'Ninguno' : row.group ?? '-',
		sortable: true,
	},
	{
		name: 'sessions',
		selector: (row) => row.sessions,
		cell: (row) => numberWithSpaces(row.sessions, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'pages',
		selector: (row) => row.pages,
		cell: (row) => numberWithSpaces(row.pages, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'sales',
		selector: (row) => row.sales,
		cell: (row) => numberWithSpaces(row.sales) + '€',
		sortable: true,
		width: '100px',
	},
	{
		name: 'bookings',
		selector: (row) => row.bookings,
		cell: (row) => numberWithSpaces(row.bookings, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'pax',
		selector: (row) => row.pax,
		cell: (row) => numberWithSpaces(row.pax, 0),
		sortable: true,
		width: '100px',
	},
	{
		name: 'salePerVisitor',
		selector: (row) => row.bookings,
		cell: (row) => numberWithSpaces(row.sessions ? (row.sales / row.sessions) : 0) + '€',
		sortable: true,
		width: '100px',
	},
	{
		name: 'conversion',
		selector: (row) => row.bookings,
		cell: (row) => numberWithSpaces((row.sessions ? (row.bookings / row.sessions) : 0) * 100) + '%',
		sortable: true,
		width: '100px',
	},
	{
		name: 'unfinished',
		selector: (row) => row.bookings,
		cell: (row) => numberWithSpaces((row.startedBookings ? ((row.startedBookings - row.bookings) / row.startedBookings) : 0) * 100) + '%',
		sortable: true,
		width: '100px',
	},
	
]
function getMonday(date) {
	let d = date ? new Date(date) : new Date()
	const day = d.getDay()
	const diff = d.getDate() - day + (day === 0 ? -6 : 1)
	return new Date(d.setDate(diff))
}
const getDefaultDates = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear(), 0, 1), 0), parseDate(new Date(dt1.getFullYear(), 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth() + 1, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 0),
				parseDate(new Date(dt1.getFullYear(), new Date(dt1).getMonth(), 1), 1),
		  ]
		: [parseDate(getMonday(dt1), 0), parseDate(new Date(getMonday(dt1).setDate(getMonday(dt1).getDate() + 6)), 1)]
}
const goBackward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() - 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() - 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() - 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
		: [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 7), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() - 1), 1),
		  ]
}
const goForward = (mode, dts) => {
	const [dt1, dt2] = dts.map((dt) => new Date(dt))

	return mode === 1
		? [parseDate(new Date(dt1.getFullYear() + 1, 0, 1), 0), parseDate(new Date(dt2.getFullYear() + 1, 11, 31), 1)]
		: mode === 2
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth() + 1, 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth() + 2, 0), 1),
		  ]
		: mode === 3
		? [
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 0),
				parseDate(new Date(dt1.getFullYear(), dt1.getMonth(), dt1.getDate() + 1), 1),
		  ]
		: [
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 1), 0),
				parseDate(new Date(dt2.getFullYear(), dt2.getMonth(), dt2.getDate() + 7), 1),
		  ]
}

const parseDate = (d = Date.prototype, i, time = true) => {
	let l = new Date(d.getTime() - d.getTimezoneOffset() * 60000).toISOString().substring(0, 10)
	if (!time) return l
	l += i ? 'T23:59' : 'T00:00'
	l = l.replace('T', ' ')
	return l
}

const defaultFilters = {
	venueId: 0,
	source: [],
	medium: [],
	campaign: [],
	group: [],
	date: ['', ''],
	dateLast: ['','']
}
const icons = ['ti-arrow-down down', 'ti-equal equal', 'ti-arrow-up up']

const config = {
	page: 'trackingsPage',
	filtersInterface: defaultFilters,
	localStorageItem: 'trackings-filters',
	preferencesInterface: { autoFilters: 0 },
	rows: [
		[
			{
				id: 'slct-tablao',
				key: 'venueId',
				type: 'select',
				role: 'filter',
				label: 'Tablao',
				preferences: ['onlyOne', 'autoHide', 'showCurrentValue', 'toggleSelects'],
				optionsAndTags: () => [
					[
						{ value: 0, name: 'Todos' },
						{ value: 1, name: 'Cardamomo' },
						{ value: 2, name: '1911' },
					],
					[],
				],
			},
			{ key: 'date', type: 'date', label: 'Creación', role: 'filter' },
			{ key: 'dateLast', type: 'date', label: 'Comparación', role: 'filter' },
		],
		[
			{
				id: 'slct-source',
				key: 'source',
				type: 'select',
				role: 'filter',
				placeholder: 'Source',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('source')
					return mapOptions('source', options)
				},
			},
			{
				id: 'slct-medium',
				key: 'medium',
				type: 'select',
				role: 'filter',
				placeholder: 'Medium',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('medium')
					return mapOptions('medium', options)
				},
			},
			{
				id: 'slct-campaign',
				key: 'campaign',
				type: 'select',
				role: 'filter',
				placeholder: 'campaign',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('campaign')
					return mapOptions('campaign', options)
				},
			},
			{
				id: 'slct-group',
				key: 'group',
				type: 'select',
				role: 'filter',
				placeholder: 'group',
				preferences: ['selectAllOption', 'showCheckedValuesTag', 'multiSelect', 'toggleSelects'],
				optionsAndTags: async () => {
					let options = await getOptions('group')
					return mapOptions('group', options)
				},
			},
		],
		[{ key: 'autoFilters' }, [{ key: 'clearFilters' }, { key: 'applyFilters' }]],
	],
}
const defaultPreferences = {
	autoFilters: 0,
}
const getIconBasedOnCalc = (type, ...data) => {
	let calc = () => {}
	let [r1, r2] = [Number(data[0]), Number(data[1])]

	switch (type) {
		case 'conversion':
		case 'salePerVisitor':
			calc = (x, y) => Number(x) / Math.max(1, Number(y))
			r1 = calc(data[0], data[1])
			r2 = calc(data[2], data[3])
			break

		case 'unfinished':
			calc = (x, y) => (Number(x) - Number(y)) / Math.max(1, Number(x))
			r1 = calc(data[0], data[1])
			r2 = calc(data[2], data[3])
			break

		default:
			break
	}

	const percentageDifference = r2 ? ((r1 - r2) / r2) * 100 : 0

	return r1 === r2 ? 1 : r1 > r2 ? 2 : 0, Number(percentageDifference.toFixed(2))
}

const sumTrackings = (arr) => {

    let acc = {sessions: 0, pages: 0, sales: 0, bookings: 0, startedBookings: 0, pax: 0}
	
    return arr.reduce((total, obj) => {
        for (let key of Object.keys(acc)) {
			total[key] += obj[key]
		}
		return total
    }, acc)

}

const content = {
	sessions: {icon: 'ti-eye', title: 'Visitas'},
	pages: {icon: 'ti-file', title: 'Paginas visitadas'},
	sales: {icon: 'ti-coins', title: 'Total vendido'},
	pax: {icon: 'ti-ticket', title: 'Nº de tickets'},
	bookings: {icon: 'ti-friends', title: 'Nº de reservas'},
	salePerVisitor: {icon: 'ti-user', title: 'Venta potencial por visitante'},
	conversion: {icon: 'ti-graph', title: 'Porcentaje de Conversión'},
	unfinished: {icon: 'ti-mood-sad', title: 'Porcentaje de abandono' }
}

const mapOptions = (type, opts) => {

	let options = Array.isArray(opts[type]) ? opts[type] : [opts[type]]
	let types = ['campaign', 'group', 'source', 'medium'].filter(t => t !== type)
	let result = []
	for (let opt of  options) {

		if (opt[type] === null) continue

		let name = String(opt[type])
			.replace(/%20/gi, ' ') // Reemplaza %20 por espacio
			.replace(/%2B/gi, '+') // Reemplaza %2B por +
			.replace(/%2F/gi, '/') // Reemplaza %2F por /
			.replace(/%3A/gi, ':') // Reemplaza %3A por :
			.replace(/%3F/gi, '?') // Reemplaza %3F por ?
			.replace(/%3D/gi, '=') // Reemplaza %3D por =
		
		result.push({
			name: opt[type] === '' ? 'Ninguno' : name,
			value: opt[type] === '' ? 'empty' : opt[type],
			dependencies: {
				[types[0]]: String(opt[types[0]]).split(',').map(v => v==='' ? 'empty' : v),
				[types[1]]: String(opt[types[1]]).split(',').map(v => v==='' ? 'empty' : v),
				[types[2]]: String(opt[types[2]]).split(',').map(v => v==='' ? 'empty' : v),
			}
		})
	}
	if (type==='group') console.log(result)

	return [result,[]]
}

export {
	columnsComputedTracking,
	getDefaultDates,
	goBackward,
	goForward,
	parseDate,
	defaultFilters,
	icons,
	numberWithSpaces,
	config,
	defaultPreferences,
	getMonday,
	getIconBasedOnCalc,
	sumTrackings,
	content
}
