import WelcomeComponent from "../components/WelcomeComponent";
import DashboardComponent from "../components/DashboardComponent";
const HomePage = () => {
  return (
    <div className="row">
      <div className="col-12">
        <WelcomeComponent />
        <DashboardComponent />
      </div>
    </div>
  );
};
export default HomePage;
