import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const columnsCashiers = [
    {
        name: 'id',
        selector: row => row.id,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Method',
        selector: row => row.method,
        sortable: true
    },
    {
        name: 'Type',
        selector: row => row.type,
        cell: props => {
            if (props.type === 'total') {
                return <span className="badge bg-light-success text-success">{props.type}</span>
            } else {
                return <span className="badge bg-light-warning text-warning">{props.type}</span>
            }
        },
        sortable: true,
        width: '100px'
    },
    {
        name: 'Amount',
        selector: row => row.amount,
        sortable: true,
        width: '100px',
        cell: props => props.amount ? props.amount.toFixed(2) + ' €' : ''
    },
    {
        name: 'Cashier',
        selector: row => row.cashier,
        sortable: true
    },
    {
        name: 'User Name',
        selector: row => row.userId,
        sortable: true,
        width: '100px'
    },
    {
        name: 'Booking Id',
        selector: row => row.bookingId,
        sortable: true,
        cell: (props) => (
            <a href={`booking/edit/${props.bookingId}`} className='rmb-text-primary'>
                {props.bookingId}
            </a>
        )
        
    },
    {
        name: 'Created At',
        selector: row => row.createdAt,
        sortable: true,
        cell: props => moment(props.createdAt).format('DD/MM/YYYY HH:mm:ss')
    }
]

export { columnsCashiers }


