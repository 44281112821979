import { useEffect, useState } from 'react'
import { getBookingLogs } from '../services/apiService'
import useApp from '../utils/useApp'



const BookingLogs = ({bookingId}) => {

    const { state:{ user }} = useApp()
    const [logs, setLogs] = useState([])

    useEffect(() => {
        getBookingLogs(user.lastVenue, bookingId)
        .then((response) => {
            if(response.result === 'ok') {
                setLogs(response.log)
            }
        })
    }, [bookingId, user.lastVenue])

    
    
    return (
        <div className=" mb-4 px-5 py-4">
            <div className="row">
                <div className="col-12">
                    {logs &&
                        <>
                            <h3 className='card-title fs-6 fw-semibold mb-3'>Logs Booking</h3>
                            <div className="logs-wrapper " dangerouslySetInnerHTML={{ __html: logs }} />
                        </>
                    }
                    
                </div>
            </div>
        </div>
    )

}

export default BookingLogs