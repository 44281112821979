import React from 'react';
import moment from 'moment'
import 'moment/locale/es'
moment.locale('es')

const LogForm = ({data}) => {

    return (
        <form className="mt-4 px-4">
            <div className="d-sm-flex align-items-center justify-content-between mb-3">
                <div>
                    <div className="form-group">
                        <h6 className="mb-0 fs-4 mb-2">Id</h6>
                        <input type="text" className="form-control" id="logId" placeholder="Id" defaultValue={data.id} disabled readOnly />
                    </div>
                </div>
            </div>
            
            
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Tipo</h6>
                <input type="text" className="form-control" id="logType" defaultValue={data.type} readOnly />
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">IP</h6>
                <input type="text" className="form-control" id="logIp" defaultValue={data.ip} readOnly />
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Id Usuario</h6>
                <input type="text" className="form-control" id="logUserId" defaultValue={data.userId} readOnly />
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Id Dispositivo</h6>
                <input type="text" className="form-control" id="logDeviceId" defaultValue={data.deviceId} readOnly />
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Acción</h6>
                <textarea className="form-control" rows="6" id="logAction" defaultValue={data.action && JSON.stringify(data.action, null, 2)} readOnly ></textarea>
            </div>
            <div className="form-group mt-4">
                <h6 className="mb-0 fs-4 mb-2">Id Reserva</h6>
                <input type="text" className="form-control" id="logBookingId" defaultValue={data.bookingId} readOnly />
            </div>

            <div className="d-flex align-items-right fs-2 mt-4">Actualizado: {moment(data.updatedAt).fromNow()} | Creado: {moment(data.createdAt).fromNow()}</div>
        </form>
        
    )
}

export default LogForm;