import { useEffect, useState } from 'react';
import BookingTablesDatatable from '../parts/BookingTablesDatatable';
import moment from 'moment'
import InputSelect from '../components/InputSelect/InputSelect';
import { getOptions, getDataApi } from '../services/apiService';
import 'moment/locale/es'
moment.locale('es')

const BookingForm = ({data, sendToUpdate }) => {
    const [formData, setFormData] = useState({})
    const [users, setUsers] = useState([])
    const [origins, setOrigins] = useState([])
    const [fieldsToSend, setFieldsToSend] = useState([])

    useEffect(() => {
        setFormData(data)
        console.log(data)
    }, [data])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFieldsToSend(new Set([...fieldsToSend, name]))
        setFormData({
            ...formData,
            [name]: value,
        })
    }

    const handleSave = () => {
        if(fieldsToSend.size > 0) {
            const dataToSend = Object.keys(formData)
            .filter((key) => fieldsToSend.has(key))
            .reduce((obj, key) => {
                obj[key] = formData[key];
                return obj;
            }, {})

            //console.log('dataToSend', dataToSend)
            sendToUpdate(dataToSend)
        }
    }

    const getUsers = async () => {
        const res = await getOptions('userId')
        const options = res.userId.map(({id, name, origins}) => {
            return {name: name, value: id,}
        })
        setUsers(options)
    }

    const getOrigins = async () => {
        const res = await getDataApi(0, 'origin', 0, 3000, null, null, 'id', 'asc')
        const options = Object.values(res.data).map((origin) => {
            return {value: origin.id, name: origin.name || origin.id}
        })
        setOrigins(options)
    }

    useEffect(() => {
        getUsers()
        getOrigins()
    }, [])

    return (
        <form className="px-4 booking-form">
            <div className='booking-group booking-summary'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Resumen reserva</h3>
                <div className="d-sm-flex align-items-center mb-3">
                    <div className='form-group mt-2 col-4'>
                        <h6 className="mb-0 fs-4 mb-2">Id</h6>
                            <input type="text" className="form-control" id="bookingId" placeholder="Id" defaultValue={formData.id} disabled />
                    </div>

                    <div className="form-group mt-2 p-3 col-8">
                        <h6 className="mb-0 fs-4 mb-2">Localizador</h6>
                        <input type="text" className="form-control" name="locator" id="bookingLocator" defaultValue={formData.locator} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="d-sm-flex align-items-center mb-3">
                    <div className="form-group mb-3 col-8">
                        <h6 className="mb-0 fs-4 mb-2">Fecha</h6>
                        <input 
                            type="date" 
                            className="form-control" 
                            name="date" 
                            id="bookingDate" 
                            defaultValue={formData.date ? formData.date.substring(0, 10) : ''} 
                            onChange={() => {}} 
                            disabled
                        />
                    </div>
                </div>

                <div className="d-sm-flex align-items-center mb-3">
                    <div className="form-group col-6">
                        <h6 className="mb-0 fs-4 mb-2">Pase (Shift Id)</h6>
                        <input type="text" className="form-control" name="shiftId" id="bookingShiftId" defaultValue={formData.shiftId} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-6 p-3">
                        <h6 className="mb-0 fs-4 mb-2">Id Evento</h6>
                        <input type="text" className="form-control" name="eventId" id="bookingEventId" defaultValue={formData.eventId }onChange={handleInputChange} />
                    </div>
                </div>
                <div className="form-group mt-4">
                    { formData.tables && formData.tables.length > 0 &&
                        <BookingTablesDatatable bookingId={formData.id} dataTables={formData.tables} />
                    }
                     { !formData.tables &&
                        <div className='alert alert-warning'>
                            No hay mesas asociadas
                        </div>
                    }
                </div>
               
                
            </div>

            <div className='booking-group booking-payment-info'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Estados de reserva</h3>
                <div className='form-group mb-4'>
                    <h6 className="mb-0 fs-4 mb-3 mt-4">Filtro</h6>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input primary"
                            type="radio"
                            name="filter"
                            id="openFilterStatus"
                            defaultValue="open"
                            checked={formData.filter === "open"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="openFilterStatus">Open</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input success"
                            type="radio"
                            name="filter"
                            id="assignedFilterStatus"
                            defaultValue="assigned"
                            checked={formData.filter === "assigned"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="assignedFilterStatus">Assigned</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input
                            className="form-check-input warning"
                            type="radio"
                            name="filter"
                            id="pendingFilterStatus"
                            defaultValue="pending"
                            checked={formData.filter === "pending"}
                            onChange={handleInputChange}
                        />
                        <label className="form-check-label" htmlFor="pendingFilterStatus">Pending</label>
                    </div>
                    
                </div>
                <div className="form-group mt-4">
                    <h6 className="mb-0 fs-4 mb-3">Estado</h6>
                    <select
                        className="form-select"
                        name="status"
                        value={formData.status}
                        onChange={handleInputChange}
                    >
                        <option value="init">Init</option>
                        <option value="pending">Pending</option>
                        <option value="confirmed">Confirmed</option>
                        <option value="reception">Reception</option>
                        <option value="seated">Seated</option>
                        <option value="closed">Closed</option>
                        <option value="blocked">Blocked</option>
                        <option value="cancel">Cancel</option>
                        <option value="removed">Removed</option>
                        <option value="noshow">Noshow</option>
                        <option value="moved">Moved</option>
                    </select>
                </div>
            </div>

            <div className='booking-group ocupation-info'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Ocupación</h3>
                <div className="d-sm-flex align-items-center">
                    <div className="form-group mt-2 col-6">
                        <h6 className="mb-0 fs-4 mb-2">Pax</h6>
                        <input type="number" className="form-control" name="pax" id="bookingPax" defaultValue={formData.pax} onChange={handleInputChange} />
                    </div>
                    <div className="form-group mt-2 col-6 p-3">
                        <h6 className="mb-0 fs-4 mb-2">Accesible</h6>
                        <input type="text" className="form-control" name="access" id="bookingAccess" defaultValue={formData.access} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="d-sm-flex align-items-center mb-3">
                    <div className="form-group col-6">
                        <h6 className="mb-0 fs-4 mb-2">Comidas</h6>
                        <input type="number" className="form-control" name="meals" id="bookingMeals" defaultValue={formData.meals} onChange={handleInputChange} />
                    </div>
                    <div className="form-group col-6 p-3">
                        <h6 className="mb-0 fs-4 mb-2">Niños/as</h6>
                        <input type="number" className="form-control" name="children" id="bookingChildren" defaultValue={formData.children} onChange={handleInputChange} />
                    </div>
                </div>
            </div>

            <div className='booking-group booking-client'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Datos cliente</h3>
                <div className="form-group mt-4 col-10">
                    <h6 className="mb-0 fs-4 mb-2">Nombre</h6>
                    <input type="text" className="form-control" name="name" id="bookingName" defaultValue={formData.name} onChange={handleInputChange} />
                </div>
                <div className="d-sm-flex align-items-center mb-3">
                    <div className="form-group mt-4 col-6">
                        <h6 className="mb-0 fs-4 mb-2">Email</h6>
                        <input type="text" className="form-control" name="email" id="bookingEmail" defaultValue={formData.email} onChange={handleInputChange} />
                    </div>
                    <div className="form-group mt-4 p-3 col-6">
                        <h6 className="mb-0 fs-4 mb-2">Teléfono</h6>
                        <input type="text" className="form-control" name="phone" id="bookingPhone" defaultValue={formData.phone} onChange={handleInputChange} />
                    </div>
                </div>
                <div className="form-group mt-4">
                    <h6 className="mb-0 fs-4 mb-2">País</h6>
                    <input type="text" className="form-control" name="country" id="bookingCountry" defaultValue={formData.country} onChange={handleInputChange} />
                </div>
            </div>

            

            <div className='booking-group booking-notes'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Notas</h3>
                <div className="form-group mt-4">
                    <h6 className="mb-0 fs-4 mb-2">Notas</h6>
                    <textarea className="form-control" rows="6" name="notes" id="bookingNotes" defaultValue={formData.notes} onChange={handleInputChange}></textarea>
                </div>

                <div className="form-group mt-4">
                    <h6 className="mb-0 fs-4 mb-2">Iconos</h6>
                    <textarea className="form-control" rows="6" name="icons" id="bookingIcons" defaultValue={formData.icons && JSON.stringify(formData.icons, null, 2)} onChange={handleInputChange}></textarea>
                </div>
            </div>

            <div className='booking-group booking-origin mb-4'>
                <h3 className='card-title fw-semibold fs-6 mt-4'>Origen</h3>

                <div className="form-group mt-4">
                    <h6 className="mb-0 fs-4 mb-2">Id Dispositivo</h6>
                    <input type="text" className="form-control" name="deviceId" id="bookingDeviceId" defaultValue={formData.deviceId} onChange={handleInputChange} />
                </div>
                <div className="d-sm-flex align-items-center mt-3">
                    <div className="form-group mt-2 col-6">
                        <InputSelect 
                            selectId='slct-origin'
                            name='origin'
                            label='Origen'
                            showCheckedValuesTag={false}
                            placeholder='Seleccionar...'
                            onInput={handleInputChange}
                            options={origins}
                            defaultValue={formData.origin || 0}
                            toggleSelects={true}
                            multiSelect={false}
                            autoHide={false}
                            showCurrentValue={true}
                            searchable={true}
                            selectAllOption={false}
                        />
                    </div>
                    <div className="form-group mt-2 col-6 p-3">
                        <InputSelect 
                            selectId='slct-sellerId'
                            name='sellerId'
                            label='Vendedor'
                            showCheckedValuesTag={false}
                            placeholder='Seleccionar...'
                            onInput={handleInputChange}
                            options={users}
                            defaultValue={formData.sellerId || 0}
                            toggleSelects={true}
                            multiSelect={false}
                            autoHide={false}
                            showCurrentValue={true}
                            searchable={true}
                            selectAllOption={false}
                        />
                    </div>
                </div>
                <div className="form-group mt-2">
                    <h6 className="mb-0 fs-4 mb-2">Etiquetas</h6>
                    <textarea className="form-control" rows="6" name="tags" id="bookingTags" defaultValue={formData.tags && JSON.stringify(formData.tags, null, 2)} onChange={handleInputChange} ></textarea>
                </div>
            </div>
            <div className="d-flex align-items-right fs-2">Actualizado: {moment(formData.updatedAt).fromNow()} | Creado: {moment(formData.createdAt).fromNow()}</div>
            <button type="button" className="mt-4 btn rmb-btn-primary" onClick={handleSave}>
                Guardar
            </button>


        </form>
        
    )
}

export default BookingForm;