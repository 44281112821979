
let colors = [
    '#62a1ff',
    '#27af66',
    '#8b62ff'
]

const SalesDensitySquare = ({
    eje_x,
    eje_y,
    cell,
    sales,
    colorizedBy = 0,
    findMaxes,
    style,
    propsToConcat = [
        {iconClassName: '', propName: '', colorizedByName: '', visibleText: ''}
    ],
}) => {

    // eje_x == 0 && eje_y == 0 -> corner top left
    // eje_x == 0 && eje_y == 7 -> dom cell
    // eje_x == 0 && eje_y != 0 -> all day cells
    // eje_y > 5 -> all weekends
    // ...

    const handleClassName = () => {
        let className = `sls-dty-tb-r sls-dty-cell ${
            eje_x===0 && eje_y===0 ? "corner" :
            eje_x===0 && eje_y>5 ? "days weekends" :
            eje_x===0 && eje_y!==0 ? "days" :
            eje_x!==0 && eje_y===0 ? "hours" :
            eje_x!==0 && eje_y===7 ? "data bottom" :
            "data"
        }`
        return className
    }

    const handleInner = () => {
        if (eje_x===0 && eje_y===0) {  
            return null
        }else if(eje_x===0 && eje_y!==0){
            return cell.substring(0,3)
        }else if(eje_x!==0 && eje_y===0){
            return cell.substring(cell.length - 2)
        }else{
            return (
                <>
                <div className="tooltiptext">
                    { sales[cell] ?
                        <>
                        {   propsToConcat.map(({propName,iconClassName,visibleText})=>{
                            return (
                                <p key={propName}>{sales[cell][propName] + visibleText}<i className={iconClassName}></i></p> 
                            )})
                        }
                        {/* <p>{sales[cell].sales}<i className="ti ti-ticket"></i></p> 
                        <p>{sales[cell].pax}<i className="ti ti-user"></i></p> 
                        <p>{sales[cell].total.toFixed(2) + "€"}</p> */}
                        </>
                        :
                        "0 ventas"
                    }
                </div>
                </>
                
            )
        }
    }

    const hslToRgb = (h, s, l) => {
        let r, g, b;
        if(s === 0){
            r = g = b = l; // achromatic
        }else{
            const hue2rgb = (p, q, t) => {
                if(t < 0) t += 1;
                if(t > 1) t -= 1;
                if(t < 1/6) return p + (q - p) * 6 * t;
                if(t < 1/2) return q;
                if(t < 2/3) return p + (q - p) * (2/3 - t) * 6;
                return p;
            };
    
            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1/3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1/3);
        }
    
        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    }

    const handleStyle = () => {
        
        let [r,g,b] = style===2 ? hslToRgb(
            sales[cell] ? ((1 - (sales[cell][propsToConcat[colorizedBy].propName] / sales['max_'+propsToConcat[colorizedBy].propName]))*120)/360 :
            0, 0.9, 0.5) : [0, 0, 0];

        let rgb = `rgba(
            ${findMaxes || style===1 ? "0" : style===2 ? r.toString() :  "39"}, 
            ${findMaxes || style===1 ? "0" : style===2 ? g.toString() : "175"}, 
            ${findMaxes || style===1 ? "0" : style===2 ? b.toString() : "102"},`
        
        let backgroundColor = ''

        if (findMaxes && sales[cell]) {
            let maxFinded = false
            propsToConcat.forEach(({propName},i)=>{
                if (sales['max_'+propName] === sales[cell][propName]) {
                    backgroundColor = colors[i]
                    maxFinded = true
                }
            })
            if (!maxFinded)
            backgroundColor = rgb + `${sales[cell][propsToConcat[colorizedBy].propName] / sales['max_'+propsToConcat[colorizedBy].propName]/1.5}`
        } else {
            backgroundColor = `${
                //hue coloritation
                style===2 ? rgb + `${sales[cell] ? sales[cell][propsToConcat[colorizedBy].propName] / sales['max_'+propsToConcat[colorizedBy].propName] : 0})` :
                //selectable colorization
                rgb + `${sales[cell] ? sales[cell][propsToConcat[colorizedBy].propName] / sales['max_'+propsToConcat[colorizedBy].propName] : 0})`
            }`
        }

        if (eje_x!==0 && eje_y!==0) return { backgroundColor: backgroundColor }
    }

    return (
        <div id={`${eje_x}-${eje_y}`} className={handleClassName()}>
            <div style={handleStyle()}>
                {handleInner()}
            </div>
        </div>
    )

}
export default SalesDensitySquare
